// ReportTypeSelector.jsx

import React from 'react';
import {
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
  } from '@mui/material';
  import AssignmentIcon from '@mui/icons-material/Assignment';

const ReportTypeSelector = ({ selectedReportType, setSelectedReportType, loading }) => {
  // Define styles for the radio buttons
  const radioStyles = {
    '& .MuiSvgIcon-root': {
      borderRadius: '50%', // Kulaté okraje
      backgroundColor: '#ffffff', // Výplň bílé barvy
      color: '#e96323', // Oranžová barva prstence
      fontSize: '20px', // Velikost ikony (zmenšení)
      transition: 'transform 0.2s ease', // Animace pro plynulý přechod
    },
    '&.Mui-checked': {
      color: '#e96323', // Barva prstence po výběru
      '& .MuiSvgIcon-root': {
        fontSize: '22px', // Zvýšení velikosti oranžového bodu při výběru
      },
    },
  };


  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ display: 'flex', alignItems: 'center', color: '#E96323', fontWeight: 'bold' }}
      >
        <AssignmentIcon sx={{ marginRight: '8px' }} />
        Typ reportu
      </Typography>
      <FormControl component="fieldset" disabled={loading}>
        <RadioGroup
          row
          aria-label="report-type"
          name="report-type"
          value={selectedReportType}
          onChange={(e) => setSelectedReportType(e.target.value)}
          sx={{ marginLeft: '5px' }}
        >
          <FormControlLabel
            value="basic"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>Základní</Typography>}
          />
          <FormControlLabel
            value="advanced"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>Pokročilý</Typography>}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ReportTypeSelector;
