// SnackbarToast.jsx

import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const AlertToast = React.forwardRef(function AlertToast(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      sx={{
        backgroundColor: '#E96323',
        color: '#FFFFFF',
        '& .MuiAlert-icon': {
          color: '#FFFFFF',
        },
      }}
    />
  );
});

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const SnackbarToast = ({ toast, handleCloseToast }) => {
  return (
    <Snackbar
      open={toast.open}
      autoHideDuration={6000}
      onClose={handleCloseToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
      sx={{ bottom: { xs: 100, sm: 140, md: 40 } }}
    >
      <AlertToast onClose={handleCloseToast} severity={toast.severity}>
        {toast.message}
      </AlertToast>
    </Snackbar>
  );
};

export default SnackbarToast;
