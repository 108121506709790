import {useState} from 'react';
import './pageTabs.css';

function PageTabs(props) 
{
    const [activeTab, setActiveTab] = useState(0);

    const tab_buttons = [];
    const tabs = [];

    for(let i = 0; i < props.tabs_name.length; i++)
    {
        tab_buttons.push((<button className={`page-tab-nav-button ${activeTab===i ? 'page-tab-nav-button-active' : ''}`} onClick={() => setActiveTab(i)}>{props.tabs_name[i]}</button>));
        tabs.push((<>{ activeTab===i && props.tabs[i] }</>));
    }

    return (
        <>
            <div className='page-tab-nav'>
                {tab_buttons}
                {/*<button className={`fve-nav-tab ${activeTab===0 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(0)}>Přehled</button>
                <button className={`fve-nav-tab ${activeTab===1 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(1)}>STŘÍDAČE FVE 1 - Fronius</button>
                <button className={`fve-nav-tab ${activeTab===2 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(2)}>STŘÍDAČE FVE 2 - SolarEdge</button>*/}
            </div>
            <div className='page-tab-body'>
                {tabs}
            </div>
        </>
    );
};

export default PageTabs;