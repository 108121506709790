import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Menu from './Main_APP/Menu';
import LoginPage from './Main_APP/LoginPage'; 
import EnergyFlowOverview from './components/EnergyFlowOverview';
import FVEOverview from './components/FVEOverview';
import BESSOverview from './components/BESSOverview';
import Dashboard from './components/Dashboards';
import SpotMarket from './components/SpotMarket';
import Home from './Main_APP/Home';
import Diagram from './components/Diagram';
import Electricity from './components/Electricity';
import Prediction from './components/Prediction';
import Economics from './components/Economics';
import Alerts from './components/Alerts';
import Reports from './components/Reports';
import Test from './components/test';
import Test2 from './components/Test2';
import { MQTTContextProvider } from './components/MQTTConn';
import Footer from './Main_APP/Footer';
import Header from './Main_APP/Header'; 

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('isAuthenticated');
    if (loggedIn) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Router>
      <div className="main-container">
        {!isAuthenticated ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <>
            <Menu isOpen={menuOpen} toggleMenu={toggleMenu} />
            <MQTTContextProvider>
              <div className="content-area">
                <Header />  {/* Použití komponenty Header */}

                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/energy-flow" element={<Diagram />} />
                  <Route path="/overview" element={<EnergyFlowOverview />} />
                  <Route path="/fve" element={<FVEOverview />} />
                  <Route path="/bess" element={<BESSOverview />} />
                  <Route path="/electricity" element={<Electricity />} />
                  <Route path="/spot-market" element={<SpotMarket />} />
                  <Route path="/prediction" element={<Prediction />} />
                  <Route path="/dashboards" element={<Dashboard />} />
                  <Route path="/economics" element={<Economics />} />
                  <Route path="/alerts" element={<Alerts />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/test" element={<Test />} />
                  <Route path="/test2" element={<Test2 />} />
                </Routes>

                <Footer />
              </div>
            </MQTTContextProvider>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
