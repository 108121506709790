import {WriteHeader, WriteValue} from "./common";

function DrawSEManagerOverview(props)
{
  const x = props.x;
  const y = props.y;
  const x_box = props.x_box;
  const y_box = props.y_box;
  const data = props.INV;

  const img_size_x = 150;
  const img_size_y = 150;
  const info_box_size_x = 350;
  const info_box_size_y = 150;

  return (
    <g>
      <image href="images/MANAGER.png" x={x - (img_size_x / 2)} y={y - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
      <circle fill="#E96323" cx={x} cy={y} r="10" class="shadow"/>
      <path d={`M ${x} ${y} L ${x_box} ${y_box}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={x} cy={y} r="10"/>
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 50} text='Činný výkon:'/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 75} text='Proud AC:'/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 100} text='Účiník:'/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 125} text='Napětí AC:'/>

      <WriteHeader x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 15 + 14} text='1. FÁZE' anchor={"middle"}/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 225} y={y_box - (info_box_size_y / 2) + 15 + 14} text='2. FÁZE' anchor={"middle"}/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 300} y={y_box - (info_box_size_y / 2) + 15 + 14} text='3. FÁZE' anchor={"middle"}/>

      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 50} text={`${data.I_AC_PowerA !== undefined ? data.I_AC_PowerA.toFixed(1) : 'NaN'} kW`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 225} y={y_box - (info_box_size_y / 2) + 50} text={`${data.I_AC_PowerB !== undefined ? data.I_AC_PowerB.toFixed(1) : 'NaN'} kW`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 300} y={y_box - (info_box_size_y / 2) + 50} text={`${data.I_AC_PowerC !== undefined ? data.I_AC_PowerC.toFixed(1) : 'NaN'} kW`} anchor={"middle"}/>

      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 75} text={`${data.I_AC_CurrentA !== undefined ? data.I_AC_CurrentA.toFixed(1) : 'NaN'} A`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 225} y={y_box - (info_box_size_y / 2) + 75} text={`${data.I_AC_CurrentB !== undefined ? data.I_AC_CurrentB.toFixed(1) : 'NaN'} A`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 300} y={y_box - (info_box_size_y / 2) + 75} text={`${data.I_AC_CurrentC !== undefined ? data.I_AC_CurrentC.toFixed(1) : 'NaN'} A`} anchor={"middle"}/>

      <WriteValue x={x_box - (info_box_size_x / 2) + 225} y={y_box - (info_box_size_y / 2) + 100} text={data.I_AC_PF !== undefined ? data.I_AC_PF.toFixed(2) : 'NaN'} anchor={"middle"}/>

      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 125} text={`${data.I_AC_VoltageAN !== undefined ? data.I_AC_VoltageAN.toFixed(1) : 'NaN'} V`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 225} y={y_box - (info_box_size_y / 2) + 125} text={`${data.I_AC_VoltageBN !== undefined ? data.I_AC_VoltageBN.toFixed(1) : 'NaN'} V`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 300} y={y_box - (info_box_size_y / 2) + 125} text={`${data.I_AC_VoltageCN !== undefined ? data.I_AC_VoltageCN.toFixed(1) : 'NaN'} V`} anchor={"middle"}/>
    </g>
  );
}

function DrawSESesukOverview(props)
{
  const x = props.x;
  const y = props.y;
  const x_box = props.x_box;
  const y_box = props.y_box;
  const data = props.INV;

  const img_size_x = 150;
  const img_size_y = 150;
  const info_box_size_x = 200;
  const info_box_size_y = 100;

  return (
    <g>
      <image href="images/SESUK.png" x={x - (img_size_x / 2)} y={y - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
        <circle fill="#E96323" cx={x} cy={y} r="10" class="shadow"/>
      <path d={`M ${x} ${y} L ${x_box} ${y_box}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={x} cy={y} r="10"/>
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 30} text='Činný výkon:'/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 55} text='Proud DC:'/>
      <WriteHeader x={x_box - (info_box_size_x / 2) + 15} y={y_box - (info_box_size_y / 2) + 80} text='Napětí DC:'/>

      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 30} text={`${data.DCW.toFixed(1)} kW`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 55} text={`${data.DCA.toFixed(1)} A`} anchor={"middle"}/>
      <WriteValue x={x_box - (info_box_size_x / 2) + 150} y={y_box - (info_box_size_y / 2) + 80} text={`${data.DCV.toFixed(1)} V`} anchor={"middle"}/>  
    </g>
  );
}

function DrawSEManagerTemp(props)
{
  const x = props.x;
  const y = props.y;
  const x_box = props.x_box;
  const y_box = props.y_box;
  const data = props.INV;

  const img_size_x = 150;
  const img_size_y = 150;
  const info_box_size_x = 150;
  const info_box_size_y = 80;

  return (
    <g>
      <image href="images/MANAGER.png" x={x - (img_size_x / 2)} y={y - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
      <circle fill="#E96323" cx={x} cy={y} r="10" class="shadow"/>
      <path d={`M ${x} ${y} L ${x_box} ${y_box}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={x} cy={y} r="10"/>
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={x_box + (info_box_size_x / 2) - 10} y={y_box + 10} text={`${data.I_Temp_Sink.toFixed(1)} °C`} anchor="end" size={28}/>

      <circle fill="#d51317" cx={x_box - (info_box_size_x / 2) + 20} cy={y_box + (info_box_size_y / 2) - 20} r="10"/>
      <path d={`M ${x_box - (info_box_size_x / 2) + 20} ${y_box + (info_box_size_y / 2) - 20} L ${x_box - (info_box_size_x / 2) + 20} ${y_box - (info_box_size_y / 2) + 10}`} stroke="#d51317" fill="transparent" strokeWidth="4"/>
    </g>
  );
}

function DrawSESesukTemp(props)
{
  const x = props.x;
  const y = props.y;
  const x_box = props.x_box;
  const y_box = props.y_box;
  const data = props.INV;

  const img_size_x = 150;
  const img_size_y = 150;
  const info_box_size_x = 150;
  const info_box_size_y = 80;

  return (
    <g>
      <image href="images/SESUK.png" x={x - (img_size_x / 2)} y={y - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
      {data.Temp !== undefined &&
        <>
          <rect
            x={x_box - (info_box_size_x / 2)}
            y={y_box - (info_box_size_y / 2)}
            width={info_box_size_x}
            height={info_box_size_y} // Výška boxu
            fill="rgba(0, 0, 0, 1)"
            stroke="#e96323"
            strokeWidth="4"
            rx="10" // Zkosené rohy
            ry="10"
            class="shadow"
          />
          <circle fill="#E96323" cx={x} cy={y} r="10" class="shadow"/>
          <path d={`M ${x} ${y} L ${x_box} ${y_box}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
          <circle fill="#E96323" cx={x} cy={y} r="10"/>
          <rect
            x={x_box - (info_box_size_x / 2)}
            y={y_box - (info_box_size_y / 2)}
            width={info_box_size_x}
            height={info_box_size_y} // Výška boxu
            fill="rgba(0, 0, 0, 1)"
            stroke="#e96323"
            strokeWidth="4"
            rx="10" // Zkosené rohy
            ry="10"
          />
        <WriteHeader x={x_box + (info_box_size_x / 2) - 10} y={y_box + 10} text={`${data.Temp.toFixed(1)} °C`} anchor="end" size={28}/>

        <circle fill="#d51317" cx={x_box - (info_box_size_x / 2) + 20} cy={y_box + (info_box_size_y / 2) - 20} r="10"/>
        <path d={`M ${x_box - (info_box_size_x / 2) + 20} ${y_box + (info_box_size_y / 2) - 20} L ${x_box - (info_box_size_x / 2) + 20} ${y_box - (info_box_size_y / 2) + 10}`} stroke="#d51317" fill="transparent" strokeWidth="4"/>  
      </>
    }   
    </g>
  );
}

export {DrawSEManagerOverview, DrawSEManagerTemp, DrawSESesukOverview, DrawSESesukTemp}