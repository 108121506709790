import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function LoginPage({ onLogin }) {
  const [passwordVisible, setPasswordVisible] = useState(false); // Stav pro viditelnost hesla
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.body.classList.add('login-page');
    // Zkontrolujeme velikost obrazovky a nastavíme stav isMobile
    const checkMobile = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkMobile(); // Zkontrolovat při načtení stránky
    window.addEventListener('resize', checkMobile); // Zkontrolovat při změně velikosti

    return () => {
      document.body.classList.remove('login-page');
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // Předdefinovaní uživatelé a hesla (v reálném nasazení by se nikdy neměly takto ukládat na klientské straně)
  const users = [
    { username: 'admin', password: '$2a$13$jZ1PUWK7ummpz.9DME0Y5utXXG0IvN7yubjWqCYEuS0M40SxRjWI2' },
    { username: 'jurica', password: '$2a$13$DJCX16MK9c3gs/B1ZZIrX.AzSOQieI5pUrBnQ2VN10JSXfjmcDiz.' },
    { username: 'silhavy', password: '$2a$13$bivxzRIZRfGOE.cqLqK31Of77GzY45nWMDB44tIou7XF9iJAqQ.Mm' },
    { username: 'pribyl', password: '$2a$13$tJyJKE6FMPLpRXNuPAD30.tlaFpiucIyKa3g52kIvO052/5UJN2h6' },
    { username: 'ondrej', password: '$2a$12$Qh66U3juf33s9nMTvJQ2i.pAnmza67rYlRib90f9uLL7Dr8eLJXKq'},
    { username: 'sucharda', password: '$2a$12$Ynw/3TmBvmO8USMX.cOj1.TkcohUIpoIFJ.k470w2iz4iNJEy.93K'}
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    const bcrypt = require('bcryptjs');

    // Zkontrolovat, zda existuje uživatel s tímto uživatelským jménem a heslem
    const user = users.find(user => user.username === username && bcrypt.compareSync(password, user.password));

    if (user) {
      onLogin(); // Přihlášení úspěšné
    } else {
      alert('Špatné uživatelské jméno nebo heslo');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (isMobile) {
    return (
      <div className="mobile-warning">
        <p>Tato stránka není dostupná na mobilních zařízeních.</p>
        <p>Prosím, přihlaste se z počítače nebo tabletu.</p>
      </div>
    );
  }

  return (
    <div 
      className="login-container" 
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
      }}
    >
      <div className="login-logo-container">
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Metrica Logo" className="login-logo" />
      </div>
      <div className="login-box">
        <h2>PŘÍSTUP K INSTALACI:</h2>
        <h1>SAPELI - areál NA DOLECH</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Uživatelské jméno:</label>
            <input type="text" name="username" id="username" required />
            <FontAwesomeIcon icon={faUser} className="icon" />
          </div>
          <div className="input-group">
            <label htmlFor="password">Heslo:</label>
            <input 
              type={passwordVisible ? "text" : "password"} 
              name="password" 
              id="password" 
              required 
            />
            <FontAwesomeIcon icon={faLock} className="icon" />
            <FontAwesomeIcon 
              icon={passwordVisible ? faEyeSlash : faEye} 
              className="toggle-password" 
              onClick={togglePasswordVisibility} 
            />
          </div>
          <div className="button-group">
            <button type="button">Zapomenuté heslo</button>
            <button type="submit">Přihlásit se</button>
          </div>
        </form>
      </div>
    </div>
  );
}
