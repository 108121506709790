import { useState } from "react";
import "./Modal.css"

const Modal = (props) => {

    const [activeModalTab, setActiveModalTab] = useState(0);

    const stopPropagation = (event) => {
        event.stopPropagation();
    };
    
    const tab_buttons = [];
    const tabs = [];

    if(props.tabs_name !== undefined)
    {
        for(let i = 0; i < props.tabs_name.length; i++)
        {
            tab_buttons.push((<button className={`uni-modal-nav-tab ${activeModalTab===i ? 'uni-modal-nav-tab-active' : ''}`} onClick={() => setActiveModalTab(i)}>{props.tabs_name[i]}</button>));
            tabs.push((<>{ activeModalTab===i && props.tabs[i] }</>));
        }
    }
    else
    {
        tabs.push(props.children);
    }

    return (
        <div className="uni-modal" onClick={props.close}>
          <div className="uni-modal-window" onClick={stopPropagation}>
            <div className="uni-modal-header">
                <h1>{props.name}</h1>
                <div className='uni-modal-header-cross' onClick={props.close}>&#x2716;</div>
            </div>
            <div className="uni-modal-body">
                {props.tabs_name !== undefined &&
                    <div className="uni-modal-nav">
                        {tab_buttons}
                    </div>
                }
                <div className="uni-modal-tab-content">
                    {tabs}
                </div>
            </div>
          </div>
        </div>
    );
};

export default Modal;