// ReportsForm.jsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Grid } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import publishToMQTT from '../MQTTPublish'; // Adjust the import path
import useMQTT from './components/useMQTT'; // Adjust the import path
import ReportTypeSelector from './components/ReportTypeSelector';
import AdvancedReportTypeSelector from './components/AdvancedReportTypeSelector';
import DateRangeSelector from './components/DateRangeSelector';
import AdvancedOptions from './components/AdvancedOptions';
import ReportFormatSelector from './components/ReportFormatSelector';
import GenerateReportButton from './components/GenerateReportButton';
import DownloadLinkButton from './components/DownloadLinkButton';
import SnackbarToast from './components/SnackbarToast';
import { generateYears, allMonths } from './components/constants'; // Adjust the import path

const ReportsForm = () => {
  // State variables
  const [selectedReportType, setSelectedReportType] = useState('basic');
  const [selectedType, setSelectedType] = useState('monthly');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportFormat, setReportFormat] = useState('csv');
  const [loading, setLoading] = useState(false);

  // Snackbar state
  const [toast, setToast] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const [downloadLink, setDownloadLink] = useState('');

  // Ref to store timeout ID
  const timeoutRef = useRef(null);

  // Confirmation dialog state
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const todayDate = today.toISOString().split('T')[0];
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  // Function to close Snackbar
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prev) => ({ ...prev, open: false }));
  };

  const handleGenerateReport = () => {
    // Input validation
    let valid = true;
    let errorMsg = '';

    if (selectedReportType === 'basic') {
      if (!startDate || !endDate) {
        errorMsg = 'Prosím vyberte počáteční a konečné datum.';
        valid = false;
      }
    } else if (selectedReportType === 'advanced') {
      if (selectedType === 'monthly' && !selectedMonth) {
        errorMsg = 'Prosím vyberte měsíc.';
        valid = false;
      }
      if (!selectedYear) {
        errorMsg = 'Prosím vyberte rok.';
        valid = false;
      }
    }

    if (!valid) {
      setToast({
        open: true,
        severity: 'error',
        message: errorMsg,
      });
      return;
    }

    // Open confirmation dialog
    setConfirmOpen(true);
  };

  const handleConfirmGenerate = () => {
    setConfirmOpen(false);
    setLoading(true);
    setDownloadLink('');

    // Start timeout (e.g., 30 seconds)
    timeoutRef.current = setTimeout(() => {
      setToast({
        open: true,
        severity: 'error',
        message:
          'Požadovaný report nebylo možné vytvořit. Zkuste to prosím znovu.',
      });
      setLoading(false);
    }, 30000);

    // Prepare payload for MQTT publishing
    let payload = {
      reportType: selectedReportType,
      format: reportFormat,
    };

    if (selectedReportType === 'basic') {
      payload.startDate = startDate;
      payload.endDate = endDate;
    } else if (selectedReportType === 'advanced') {
      payload.type = selectedType;
      payload.year = selectedYear.toString();
      if (selectedType === 'monthly') {
        payload.month = selectedMonth;
      }
    }

    const payloadString = JSON.stringify(payload);
    console.log('Publishing payload:', payloadString);

    // Publish to MQTT with callback
    publishToMQTT('reports/request', payloadString, (err) => {
      if (err) {
        setToast({
          open: true,
          severity: 'error',
          message: 'Chyba při odesílání reportu.',
        });
        setLoading(false);
        console.error('Publish error:', err);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      } else {
        setToast({
          open: true,
          severity: 'success',
          message: 'Požadavek na generování reportu byl odeslán.',
        });
      }
    });
  };

  // MQTT subscription for responses
  useMQTT({ setDownloadLink, setToast, setLoading, timeoutRef });

  // Generate list of years from 2024 to current year
  const years = generateYears(2024);

  // Function to filter months based on selected year
  const getFilteredMonths = () => {
    if (parseInt(selectedYear) === currentYear) {
      return allMonths.filter(
        (month) => parseInt(month.value) <= currentMonth
      );
    }
    return allMonths;
  };

  // useEffect to reset selectedMonth when selectedYear changes
  useEffect(() => {
    setSelectedMonth('');
  }, [selectedYear]);

  const months = getFilteredMonths();

  const resetForm = () => {
    setSelectedReportType('basic');
    setSelectedType('monthly');
    setSelectedMonth('');
    setSelectedYear('');
    setStartDate('');
    setEndDate('');
    setReportFormat('csv');
    setDownloadLink('');
    setLoading(false);
  };

  const handleDownloadClick = () => {
    setToast({
      open: true,
      severity: 'success',
      message: 'Report byl úspěšně stažen!',
    });
    setTimeout(() => {
      resetForm();
      setLoading(false);
    }, 2000);
  };

  // useEffect to automatically reset dependent fields when report type changes
  useEffect(() => {
    if (selectedReportType === 'basic') {
      setSelectedType('monthly');
      setSelectedMonth('');
      setSelectedYear('');
      setStartDate('');
      setEndDate('');
    } else if (selectedReportType === 'advanced') {
      setSelectedMonth('');
      setSelectedYear('');
      setStartDate('');
      setEndDate('');
      setSelectedType('monthly');
    }
  }, [selectedReportType]);

  const minDate = '2024-01-01';
  const maxDate = todayDate;

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate > maxDate) {
      setStartDate(maxDate);
    } else {
      setStartDate(selectedDate);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate > maxDate) {
      setEndDate(maxDate);
    } else {
      setEndDate(selectedDate);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          background:
            'transparent linear-gradient(177deg, #252525 0%, #252525 0%, #151515 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 5px 5px #00000029',
          border: '1px solid #989898',
          borderRadius: '10px',
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            color: '#FFFFFF',
            fontWeight: 'bold',
            mb: 3,
          }}
        >
          Generování reportů
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={3}>
            {/* Section: Report Type and Advanced Report Type */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Report Type */}
                <Grid
                  item
                  xs={12}
                  sm={selectedReportType === 'advanced' ? 6 : 12}
                >
                  <ReportTypeSelector
                    selectedReportType={selectedReportType}
                    setSelectedReportType={setSelectedReportType}
                    loading={loading}
                  />
                </Grid>

                {/* Advanced Report Type */}
                {selectedReportType === 'advanced' && (
                  <Grid item xs={12} sm={6}>
                    <AdvancedReportTypeSelector
                      selectedType={selectedType}
                      setSelectedType={setSelectedType}
                      loading={loading}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Conditional Rendering Based on Report Type */}
            {selectedReportType === 'basic' ? (
              <>
                {/* Section: Date Range */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#E96323',
                      fontWeight: 'bold',
                    }}
                  >
                    <DateRangeIcon sx={{ marginRight: '8px' }} />
                    Časový interval
                  </Typography>
                  <DateRangeSelector
                    startDate={startDate}
                    endDate={endDate}
                    loading={loading}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                  />
                </Grid>
              </>
            ) : (
              <>
                {/* Advanced Options displayed directly */}
                <Grid item xs={12}>
                  <AdvancedOptions
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    years={years}
                    months={months}
                    loading={loading}
                  />
                </Grid>

                {/* Section: Report Format */}
                <Grid item xs={12}>
                  <ReportFormatSelector
                    reportFormat={reportFormat}
                    setReportFormat={setReportFormat}
                    loading={loading}
                  />
                </Grid>
              </>
            )}

            {/* Report Format for Basic Reports */}
            {selectedReportType === 'basic' && (
              <Grid item xs={12}>
              <ReportFormatSelector
                reportFormat={reportFormat}
                setReportFormat={setReportFormat}
                loading={loading}
              />
            </Grid>
            )}

            {/* Button to Generate Report */}
            <GenerateReportButton
              handleGenerateReport={handleGenerateReport}
              loading={loading}
            />

            {/* Display Download Link */}
            {downloadLink && (
              <DownloadLinkButton
                downloadLink={downloadLink}
                handleDownloadClick={handleDownloadClick}
              />
            )}
          </Grid>
        </Box>
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Potvrzení</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Opravdu chcete vygenerovat tento report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Zrušit</Button>
          <Button onClick={handleConfirmGenerate} color="primary">
            Potvrdit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Backdrop with CircularProgress for Loading Animation */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Snackbar for toast messages */}
      <SnackbarToast toast={toast} handleCloseToast={handleCloseToast} />
    </Container>
  );
};

export default ReportsForm;
