import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table';

import React from 'react';
import Table from '../table/table'

function WriteHeader(props)
{
  const x = props.x;
  const y = props.y;
  const anchor = props.anchor || 'start';
  const text = props.text;
  const size = props.size || 16;

  return (
    <text
      x={x} // Pozice textu
      y={y} // Výška prvního řádku textu
      fill="white"
      fontSize={size} // Velikost písma
      fontFamily="Roboto, sans-serif"
      fontWeight="bold"
      text-anchor={anchor}
    >
      {text}
  </text>
  );
};

function WriteValue(props)
{
  const x = props.x;
  const y = props.y;
  const anchor = props.anchor || 'start';
  const text = props.text;
  const size = props.size || 16;

  return (
    <text
      x={x} // Pozice textu
      y={y} // Výška prvního řádku textu
      fill="white"
      fontSize={size} // Velikost písma
      fontFamily="Roboto, sans-serif"
      text-anchor={anchor}
    >
      {text}
  </text>
  );
};

function ErrorTable(props)
{
  const defaultErrorHistory = [
  ];

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor('start', {
      header: () => <span>Začátek</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('id', {
      header: () => <span>ID - Chyby</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('name', {
      header: () => <span>Název</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('duration', {
      header: () => <span>Trvání</span>,
      cell: info => info.getValue(),
    })
  ];

  const [data, _setData] = React.useState(() => [...defaultErrorHistory])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <table rules="groups">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function LimitationTable(props)
{
  const limitationData = [];

  if(props.INV.activePowerLimit !== undefined)
    limitationData.push([
      "Požadovaný činný výkon",
      props.INV.activePowerLimit.toFixed(0) + "%"
    ]);
  
  if(props.INV.reactivePowerLimit !== undefined)
    limitationData.push([
      "Požadovaný jalový výkon",
      props.INV.reactivePowerLimit.toFixed(0)
    ]);

    if(props.INV.cosPhiLimit !== undefined)
      limitationData.push([
        "Požadovaný účiník",
        props.INV.cosPhiLimit.toFixed(2)
      ]);

  /*const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Název</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('value', {
      header: () => <span>Hodnota</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('reason', {
      header: () => <span>Důvod</span>,
      cell: info => info.getValue(),
    })
  ];

  const [data, _setData] = React.useState(() => [...limitationData])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })*/

  /*return (
    <table rules="groups">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );*/

  return (<Table headers={['Název', 'Hodnota']} rows={limitationData} />);
};

function RestTable(props)
{
  const restData = [];
  const nameMap = {
    'Device_ID': { name: 'ID zařízení' },
    'Device_Vendor': { name: 'Výrobce zařízení' },
    'Device_Model': { name: 'Model zařízení' },
    'comunicationLost': { name: 'Ztráta komunikace' },
    'activePowerLimit': { name: 'Požadovaný činný výkon', unit: '%', fixed: 0 },
    'cosPhiLimit': { name: 'Požadované účiník', fixed: 2 },
    'installedAC': { name: 'Instalovaný výkon AC', unit: ' kW', fixed: 2 },
    'installedDC': { name: 'Instalovaný výkon DC', unit: ' kW', fixed: 2 },
    'I_AC_Current': { name: 'Celkový proud na AC straně', unit: ' A', fixed: 2 },
    'I_AC_CurrentA': { name: 'Proud na fázi L1', unit: ' A', fixed: 2 },
    'I_AC_CurrentB': { name: 'Proud na fázi L2', unit: ' A', fixed: 2 },
    'I_AC_CurrentC': { name: 'Proud na fázi L3', unit: ' A', fixed: 2 },
    'I_AC_VoltageAB': { name: 'Napětí mezi fázemi L1 a L2', unit: ' V', fixed: 2 },
    'I_AC_VoltageBC': { name: 'Napětí mezi fázemi L2 a L3', unit: ' V', fixed: 2 },
    'I_AC_VoltageCA': { name: 'Napětí mezi fázemi L1 a L3', unit: ' V', fixed: 2 },
    'I_AC_VoltageAN': { name: 'Napětí na fázi L1', unit: ' V', fixed: 2 },
    'I_AC_VoltageBN': { name: 'Napětí na fázi L2', unit: ' V', fixed: 2 },
    'I_AC_VoltageCN': { name: 'Napětí na fázi L3', unit: ' V', fixed: 2 },
    'I_AC_Power': { name: 'Aktuální výkon AC', unit: ' kW', fixed: 2 },
    'I_AC_Frequency': { name: 'Aktuální frekvence AC', unit: ' kW', fixed: 2 },
    'I_AC_VA': { name: 'Zdánlivý výkon AC', unit: ' VA', fixed: 2 },
    'I_AC_VAR': { name: 'Jalový výkon AC', unit: ' VAR', fixed: 2 },
    'I_AC_PF': { name: 'Účiník AC', fixed: 2 },
    'I_AC_Energy_MWH': { name: 'Celkem vyrobená elektřina', unit: ' MWh', fixed: 2 },
    'I_DC_Current': { name: 'Celkový proud na DC straně', unit: ' A', fixed: 2 },
    'I_DC_Power': { name: 'Aktuální výkon DC', unit: ' kW', fixed: 2 },
    'I_Temp_Sink': { name: 'Teplota zařízení', unit: ' °C', fixed: 2 },
    'I_Status': { name: 'Status'},
    'I_Status_Vendor': { name: 'Status výrobce'},
    'I_AC_PowerA': { name: 'Výkon na fázi L1', unit: ' kW', fixed: 2 },
    'I_AC_PowerB': { name: 'Výkon na fázi L2', unit: ' kW', fixed: 2 },
    'I_AC_PowerC': { name: 'Výkon na fázi L3', unit: ' kW', fixed: 2 },
    'I_DC_Voltage': { name: 'DC napětí', unit: ' V', fixed: 2 },
  }

  for(const key in props.INV)
  {
    if(!isNaN(props.INV[key]) || typeof props.INV[key] === 'string' || props.INV[key] instanceof String)
    {
      restData.push([
        nameMap[key] !== undefined ? nameMap[key].name : key,
        nameMap[key] !== undefined && nameMap[key].fixed !== undefined ? `${props.INV[key].toFixed(nameMap[key].fixed)}${nameMap[key] !== undefined && nameMap[key].unit !== undefined ? nameMap[key].unit : '' }` : props.INV[key]
      ]);
    }
  }

  /*const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Název</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('value', {
      header: () => <span>Hodnota</span>,
      cell: info => info.getValue(),
    })
  ];

  const [data, _setData] = React.useState(() => [...restData])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <table rules="groups">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );*/

  return (<Table headers={['Název', 'Hodnota']} rows={restData} />);
};

function InverterSmall(props)
{
  const powerLevel_INV = props.INV.I_AC_Power || 0;
  const maxPower_INV = props.MAX_POWER;
  const powerLevelWidth_INV = `${(powerLevel_INV / maxPower_INV) * 100}%`;

  return (
    <div className="inverter-wrapper-small" onClick={props.onClick}>
      {/* Blok střídače */}
      <div className={`inverter-block-small ${props.INV.I_Status === 7 ? 'inverter-block-small-error' : ''}`}>
        <div className="inverter-content-small">
          {/* Obrázek střídače a šipky */}
          <div className="inverter-image-small">
            <div className="inverter-top-bar-small">
              <h2>STŘÍDAČ Č.&nbsp;{props.number} - {props.NAME}</h2>
            </div>
            <div className='inverter-status-small'>
              {props.INV.I_Status !== 7 && <img src="/images/ikona_ok_50x50.png" alt="OK" />}
              {props.INV.I_Status === 7 && <img className='inverter-status-small-error' src="/images/ikona_pruser_50x50.png" alt="OK" />}
            </div>
              
            <img src={props.IMG} alt={props.NAME} />
            {powerLevel_INV > 0 && (
              <>
                {/* Kontejner pro levé šipky mezi DC boxem a střídačem */}
                <div className="arrow-container-left">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>

                {/* Kontejner pro pravé šipky mezi střídačem a AC boxem */}
                <div className="arrow-container-right">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>
              </>
            )}
            {/* Kontejner pro ukazatel výkonu */}
            <div className="power-container-small">
              <div className="power-info-small">AKTUÁLNÍ VÝKON:</div>
              <div className="power-bar-small">
                <div className="power-level-small" style={{ width: powerLevelWidth_INV }}></div>
                <div className="power-value-small">{powerLevel_INV.toFixed(1)} kW</div>
              </div>
              <div className="power-scale-small">
                <span>0 kW</span>
                <span>{(maxPower_INV / 2)} kW</span>
                <span>{maxPower_INV} kW</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {WriteHeader, WriteValue, ErrorTable, LimitationTable, RestTable, InverterSmall};