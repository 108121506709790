// constants.js

export const allMonths = [
    { value: '01', label: 'Leden' },
    { value: '02', label: 'Únor' },
    { value: '03', label: 'Březen' },
    { value: '04', label: 'Duben' },
    { value: '05', label: 'Květen' },
    { value: '06', label: 'Červen' },
    { value: '07', label: 'Červenec' },
    { value: '08', label: 'Srpen' },
    { value: '09', label: 'Září' },
    { value: '10', label: 'Říjen' },
    { value: '11', label: 'Listopad' },
    { value: '12', label: 'Prosinec' },
  ];
  
  export const generateYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  