import React from 'react';
import { Grid2, FormControl, TextField, Tooltip } from '@mui/material';

const DateRangeSelector = ({
  startDate,
  endDate,
  loading,
  minDate,
  maxDate,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  // Define the styles for the TextField components
  const textFieldStyles = {
    background: '#FFFFFF',
    boxShadow:
      'inset 0px 3px 1px rgba(233, 99, 35, 0.25), 0px 3px 6px rgba(0, 0, 0, 0.16)',
    border: '1px solid #707070',
    borderRadius: '10px',
    width: '100%', // Zajistí plnou šířku komponenty
  };

  return (
    <Grid2 container spacing={2}>
      {/* Start Date */}
      <Grid2 xs={12} sm={6} sx={{ minWidth: '385px' }}> {/* Nastavená minimální šířka */}
        <FormControl fullWidth>
          <Tooltip
            title="Vyberte počáteční datum pro generování reportu."
            arrow
          >
            <TextField
              label="Počáteční datum"
              type="date"
              disabled={loading}
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={handleStartDateChange}
              inputProps={{
                min: minDate,
                max: maxDate,
              }}
              sx={textFieldStyles}
            />
          </Tooltip>
        </FormControl>
      </Grid2>

      {/* End Date */}
      <Grid2 xs={12} sm={6} sx={{ minWidth: '385px' }}> {/* Nastavená minimální šířka */}
        <FormControl fullWidth>
          <Tooltip
            title="Vyberte konečné datum pro generování reportu."
            arrow
          >
            <TextField
              label="Konečné datum"
              type="date"
              disabled={loading}
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={handleEndDateChange}
              inputProps={{
                min: startDate || minDate,
                max: maxDate,
              }}
              sx={textFieldStyles}
            />
          </Tooltip>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};

export default DateRangeSelector;
