import React from 'react';
import { Grid2, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const DownloadLinkButton = ({ downloadLink, handleDownloadClick }) => {
  return (
    <Grid2 
      container 
      justifyContent="center" 
      alignItems="center" 
      xs={12} 
      sx={{ marginTop: '16px' }} // Upraveno odsazení
    >
      <Button
        variant="contained"
        href={downloadLink}
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<DownloadIcon />}
        onClick={handleDownloadClick}
        sx={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#E96323',
          color: '#FFFFFF',
          width: 'fit-content', // Nastaví šířku tlačítka podle obsahu
          '&:hover': {
            backgroundColor: '#d5581f',
            transform: 'scale(1.02)',
          },
          transition: 'background-color 0.3s, transform 0.3s',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        Stáhnout vygenerovaný report
      </Button>
    </Grid2>
  );
};

export default DownloadLinkButton;
