import React from 'react';
import Test2Reports from './Reports/ReportsForm';

const Test2 = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Test2Reports />
    </div>
  );
};

export default Test2;
