function Table(props)
{
    const header_components = [];
    const body_components = [];

    for(const header of props.headers)
    {
        header_components.push((<th>{header}</th>))
    }

    for(const row of props.rows)
    {
        const new_row = [];

        for(const column of row)
        {
            new_row.push((<td>{column}</td>))
        }

        body_components.push((<tr>{new_row}</tr>));
    }

    return (
        <table rules="groups">
            <thead>
                <tr>
                    {header_components}
                </tr>
            </thead>
            <tbody>
                {body_components}
            </tbody>
        </table>
    );
}

export default Table;