import React, { useContext } from 'react';
import { MQTTContext } from './MQTTConn';
import './EnergyFlowOverview.css'; // CSS pro tuto stránku

export default function EnergyFlowOverview() {
  const mqttData = useContext(MQTTContext);

  return (
    <div className="energy-flow-overview">
      <div className="grid-container">
        {/* Distribuční soustava EG.D */}
        {/* Wrapper pro oranžový pruh a obrázek */}
        <div className="overview-wrapper">
          {/* Oranžový pruh s textem */}
          <div className="overview-bar">
            <h2>DISTRIBUČNÍ SOUSTAVA</h2>
          </div>

          {/* Obrázek */}
          <div className="overview-img">
            <img src="/images/DS.png" alt="Distribuční soustava" />
          </div>
          <div className="grid-item">
            <div className="sub-section">
              <h3>Aktuální přehled</h3>
              <p><strong>Výkon na L1:</strong> {mqttData.powerData.AC_Power_Main_P1 !== undefined ? `${mqttData.powerData.AC_Power_Main_P1} kW` : 'Načítám...'}</p>
              <p><strong>Výkon na L2:</strong> {mqttData.powerData.AC_Power_Main_P2 !== undefined ? `${mqttData.powerData.AC_Power_Main_P2} kW` : 'Načítám...'}</p>
              <p><strong>Výkon na L3:</strong> {mqttData.powerData.AC_Power_Main_P3 !== undefined ? `${mqttData.powerData.AC_Power_Main_P3} kW` : 'Načítám...'}</p>
              <p><strong>Spotřeba z DS:</strong> {mqttData.powerData.AC_Power_Main_Consum !== undefined ? `${mqttData.powerData.AC_Power_Main_Consum} kW` : 'Načítám...'}</p>
              <p><strong>Export do DS:</strong> {mqttData.powerData.AC_Power_Main_Export !== undefined ? `${mqttData.powerData.AC_Power_Main_Export} kW` : 'Načítám...'}</p>
            </div>

            <div className="sub-section">
              <h3>Spotřeba z DS</h3>
              <p><span className="label">Spotřeba dnes:</span> <span className="value">{mqttData.daySumData.Total_Energy_Main_Import_Day !== undefined ? `${mqttData.daySumData.Total_Energy_Main_Import_Day} kWh` : 'Načítám...'}</span></p>
              <p><span className="label">Spotřeba za měsíc:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Import_Month !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Month} MWh` : 'Načítám...'}</span></p>
              <p><span className="label">Spotřeba za rok:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Import_Year !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Year} MWh` : 'Načítám...'}</span></p>
              <p><span className="label">Spotřeba od spuštění:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Import_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>

            <div className="sub-section">
              <h3>Export do DS</h3>
              <p><span className="label">Export dnes:</span> <span className="value">{mqttData.daySumData.Total_Energy_Main_Export_Day !== undefined ? `${mqttData.daySumData.Total_Energy_Main_Export_Day} kWh` : 'Načítám...'}</span></p>
              <p><span className="label">Export za měsíc:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Export_Month !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Month} MWh` : 'Načítám...'}</span></p>
              <p><span className="label">Export za rok:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Export_Year !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Year} MWh` : 'Načítám...'}</span></p>
              <p><span className="label">Export od spuštění:</span> <span className="value">{mqttData.energySumData.Total_Energy_Main_Export_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>
          </div>
        </div>
        
        {/* Bateriový systém */}
        {/* Wrapper pro oranžový pruh a obrázek */}
        <div className="overview-wrapper">
          {/* Oranžový pruh s textem */}
          <div className="overview-bar">
            <h2>BATERIOVÝ SYSTÉM</h2>
          </div>

          {/* Obrázek */}
          <div className="overview-img">
            <img src="/images/BESS.png" alt="Bateriový systém" />
          </div>
          <div className="grid-item">
            <div className="sub-section">
              <h3>Aktuální přehled</h3>
              <p><span>Výkon na L1:</span><span>{mqttData.powerData.AC_Power_L1_EL2 !== undefined ? `${mqttData.powerData.AC_Power_L1_EL2} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L2:</span><span>{mqttData.powerData.AC_Power_L2_EL2 !== undefined ? `${mqttData.powerData.AC_Power_L2_EL2} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L3:</span><span>{mqttData.powerData.AC_Power_L3_EL2 !== undefined ? `${mqttData.powerData.AC_Power_L3_EL2} kW` : 'Načítám...'}</span></p>
              <p><span>Celkový výkon:</span><span>{mqttData.powerData.AC_Power_Total_EL2 !== undefined ? `${mqttData.powerData.AC_Power_Total_EL2} kW` : 'Načítám...'}</span></p>
              <p><span>Nabití baterie:</span><span>{mqttData.bessData.SoC !== undefined ? `${mqttData.bessData.SoC} %` : 'Načítám...'}</span></p>
            </div>

            <div className="sub-section">
              <h3>Import do bateriového systému</h3>
              <p><span>Import dnes:</span><span>{mqttData.daySumData.Total_Energy_BESS_Charge_Day !== undefined ? `${mqttData.daySumData.Total_Energy_BESS_Charge_Day} kWh` : 'Načítám...'}</span></p>
              <p><span>Import za měsíc:</span><span>{mqttData.energySumData.Total_Energy_BESS_Charge_Month !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Month} MWh` : 'Načítám...'}</span></p>
              <p><span>Import za rok:</span><span>{mqttData.energySumData.Total_Energy_BESS_Charge_Year !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Year} MWh` : 'Načítám...'}</span></p>
              <p><span>Import od spuštění:</span><span>{mqttData.energySumData.Total_Energy_BESS_Charge_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>

            <div className="sub-section">
              <h3>Export z bateriového systému</h3>
              <p><span>Export dnes:</span><span>{mqttData.daySumData.Total_Energy_BESS_Discharge_Day !== undefined ? `${mqttData.daySumData.Total_Energy_BESS_Discharge_Day} kWh` : 'Načítám...'}</span></p>
              <p><span>Export za měsíc:</span><span>{mqttData.energySumData.Total_Energy_BESS_Discharge_Month !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Month} MWh` : 'Načítám...'}</span></p>
              <p><span>Export za rok:</span><span>{mqttData.energySumData.Total_Energy_BESS_Discharge_Year !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Year} MWh` : 'Načítám...'}</span></p>
              <p><span>Export od spuštění:</span><span>{mqttData.energySumData.Total_Energy_BESS_Discharge_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>
          </div>
        </div>

        {/* Fotovoltaická elektrárna */}
        {/* Wrapper pro oranžový pruh a obrázek */}
        <div className="overview-wrapper">
          {/* Oranžový pruh s textem */}
          <div className="overview-bar">
            <h2>FOTOVOLTAICKÁ ELEKTRÁRNA</h2>
          </div>

          {/* Obrázek */}
          <div className="overview-img">
            <img src="/images/FVE.png" alt="Fotovoltaická elektrárna" />
          </div>
          <div className="grid-item">
            <div className="sub-section">
              <h3>Aktuální přehled</h3>
              <p><span>Výkon na L1:</span><span>{mqttData.powerData.AC_Power_L1_EL3 !== undefined ? `${mqttData.powerData.AC_Power_L1_EL3} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L2:</span><span>{mqttData.powerData.AC_Power_L2_EL3 !== undefined ? `${mqttData.powerData.AC_Power_L2_EL3} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L3:</span><span>{mqttData.powerData.AC_Power_L3_EL3 !== undefined ? `${mqttData.powerData.AC_Power_L3_EL3} kW` : 'Načítám...'}</span></p>
              <p><span>Celkový výkon:</span><span>{mqttData.powerData.AC_Power_Total_EL3 !== undefined ? `${mqttData.powerData.AC_Power_Total_EL3} kW` : 'Načítám...'}</span></p>
              <p><span>Aktuální regulace:</span><span>{mqttData.powerData.FVERegulation !== undefined ? `${mqttData.powerData.FVERegulation} %` : 'Načítám...'}</span></p>
            </div>

            <div className="sub-section">
              <h3>Výroba FVE</h3>
              <p><span>Výroba dnes:</span><span>{mqttData.daySumData.Total_Energy_FVE_Day !== undefined ? `${mqttData.daySumData.Total_Energy_FVE_Day} kWh` : 'Načítám...'}</span></p>
              <p><span>Výroba za měsíc:</span><span>{mqttData.energySumData.Total_Energy_FVE_Month !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_Month} MWh` : 'Načítám...'}</span></p>
              <p><span>Výroba za rok:</span><span>{mqttData.energySumData.Total_Energy_FVE_Year !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_Year} MWh` : 'Načítám...'}</span></p>
              <p><span>Výroba od spuštění:</span><span>{mqttData.energySumData.Total_Energy_FVE_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>

          </div>
        </div>

        {/* Spotřeba areálu */}
        {/* Wrapper pro oranžový pruh a obrázek */}
        <div className="overview-wrapper">
          {/* Oranžový pruh s textem */}
          <div className="overview-bar">
            <h2>SPOTŘEBA AREÁLU</h2>
          </div>

          {/* Obrázek */}
          <div className="overview-img">
            <img src="/images/centralni_objekt.png" alt="Spotřeba areálu" />
          </div>
          <div className="grid-item">
            <div className="sub-section">
              <h3>Aktuální přehled</h3>
              <p><span>Výkon na L1:</span><span>{mqttData.powerData.AC_Power_Consumption_L1 !== undefined ? `${mqttData.powerData.AC_Power_Consumption_L1} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L2:</span><span>{mqttData.powerData.AC_Power_Consumption_L2 !== undefined ? `${mqttData.powerData.AC_Power_Consumption_L2} kW` : 'Načítám...'}</span></p>
              <p><span>Výkon na L3:</span><span>{mqttData.powerData.AC_Power_Consumption_L3 !== undefined ? `${mqttData.powerData.AC_Power_Consumption_L3} kW` : 'Načítám...'}</span></p>
              <p><span>Celkový výkon:</span><span>{mqttData.powerData.AC_Power_Consumption !== undefined ? `${mqttData.powerData.AC_Power_Consumption} kW` : 'Načítám...'}</span></p>
              <p><span>Stupeň soběstačnosti:</span><span>{mqttData.powerData.Selfsufficiency !== undefined ? `${mqttData.powerData.Selfsufficiency} %` : 'Načítám...'}</span></p>
            </div>

            <div className="sub-section">
              <h3>Spotřeba areálu</h3>
              <p><span>Spotřeba dnes:</span><span>{mqttData.daySumData.Total_Energy_Consumption_Day !== undefined ? `${mqttData.daySumData.Total_Energy_Consumption_Day} kWh` : 'Načítám...'}</span></p>
              <p><span>Spotřeba za měsíc:</span><span>{mqttData.energySumData.Total_Energy_Consumption_Month !== undefined ? `${mqttData.energySumData.Total_Energy_Consumption_Month} MWh` : 'Načítám...'}</span></p>
              <p><span>Spotřeba za rok:</span><span>{mqttData.energySumData.Total_Energy_Consumption_Year !== undefined ? `${mqttData.energySumData.Total_Energy_Consumption_Year} MWh` : 'Načítám...'}</span></p>
              <p><span>Spotřeba od spuštění:</span><span>{mqttData.energySumData.Total_Energy_Consumption_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_Consumption_Lifetime} MWh` : 'Načítám...'}</span></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
