const dayjs = require('dayjs');
//const utc = require('dayjs/plugin/utc');
//const timezone = require('dayjs/plugin/timezone');
//var customParseFormat = require("dayjs/plugin/customParseFormat");

//dayjs.extend(utc);
//dayjs.extend(customParseFormat);

function formatTimeFromTimestamp(timestamp, hour_sub = 0)
{
    return dayjs(timestamp).subtract(hour_sub, 'hour').format('HH:mm');
}

function formatCzechTimestamp(timestamp, hour_sub = 0)
{
    return dayjs(timestamp).subtract(hour_sub, 'hour').format('DD.MM.YYYY HH:mm');
}

function formatDate(timestamp, hour_sub = 0)
{
    return dayjs(timestamp).subtract(hour_sub, 'hour').format('DD.MM.YYYY');
}

function formatWeekDay(timestamp, hour_sub = 0)
{
    const dayMap = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'];

    return dayMap[dayjs(timestamp).subtract(hour_sub, 'hour').day()];
}

function formatWeekDayCzechDate(timestamp)
{
    const dayMap = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'];

    return dayMap[dayjs(timestamp, 'DD.MM.YYYY').day()];
}

function formatSelectedWeekDay(weekday)
{
    const dayMap = ["POUZE PONDĚLKY", "POUZE ÚTERKY", "POUZE STŘEDY", "POUZE ČTVRTKY", "POUZE PÁTKY", "POUZE SOBOTY", "POUZE NEDĚLE"];

    return weekday === undefined ? "VŠECHNY DNY" : dayMap[weekday];
}

function formatTimeRangeFromTime(time)
{
    const from = dayjs(time, "HH:mm").subtract(1, 'hour').format('HH:mm');
    const to = dayjs(time, "HH:mm").format('HH:mm');

    return `${from} - ${to}`;
}

function formatTimeRangeFromTimestamp(timestamp)
{
    const from = dayjs(timestamp).subtract(1, 'hour').format('HH:mm');
    const to = dayjs(timestamp).format('HH:mm');

    return `${from} - ${to}`;
}

function formatDateRange(from, to)
{
    return from === to ? `${formatDate(from)}` : `${formatDate(from)} AŽ ${formatDate(to)}`;
}

export {formatDate, formatWeekDay, formatWeekDayCzechDate, formatSelectedWeekDay, formatTimeRangeFromTime, formatTimeRangeFromTimestamp, formatDateRange, formatTimeFromTimestamp, formatCzechTimestamp};