import React from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const GenerateReportButton = ({ handleGenerateReport, loading }) => {
  return (
    <Grid item xs={12} align="center">
      <LoadingButton
        variant="contained"
        onClick={handleGenerateReport}
        loading={loading}
        loadingIndicator={
          <span style={{ color: '#FFFFFF' }}>Generuji...</span>
        } // Bílé zobrazení textu při načítání
        startIcon={!loading && <AnalyticsIcon sx={{ color: '#FFFFFF' }} />} // Bíla ikona
        disabled={loading}
        sx={{
          width: '100%',
          padding: '12px',
          fontSize: '16px',
          background:
            'transparent linear-gradient(180deg, #E96323 0%, #753212 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 5px 5px #00000029',
          borderRadius: '10px',
          color: '#FFFFFF', // Bílý text
          '&:hover': {
            background:
              'transparent linear-gradient(180deg, #d5581f 0%, #602610 100%) 0% 0% no-repeat padding-box', // Změna gradientu při hoveru
            transform: 'scale(1.02)',
          },
          transition: 'background-color 0.3s, transform 0.3s',
        }}
      >
        Vygenerovat report
      </LoadingButton>
    </Grid>
  );
};

export default GenerateReportButton;
