import {WriteHeader, WriteValue, InverterSmall} from "./common";

function FroniusEco25InverterSmall(props)
{
  return (
    <InverterSmall INV={props.INV} number={props.number} onClick={props.onClick} MAX_POWER={25} NAME='FRONIUS Eco 25' IMG='./images/Fronius-Eco.png' />
  );
};

function FroniusSymo20InverterSmall(props)
{
  return (
    <InverterSmall INV={props.INV} number={props.number} onClick={props.onClick} MAX_POWER={20} NAME='FRONIUS Symo 20' IMG='./images/Fronius-Eco.png' />
  );
};

function DrawFroniusOverview(props)
{
  const data = props.INV;

  const img_size_x = 250;
  const img_size_y = 250;

  const ac_box_size_x = 350;
  const ac_box_size_y = 150;
  const ac_box_x = 450;
  const ac_box_y = 125;
  const ac_box_arrow_x = 450;
  const ac_box_arrow_y = 300;

  const dc_box_size_x = 200;
  const dc_box_size_y = 100;
  const dc1_box_x = 325;
  const dc1_box_y = 525;
  const dc1_box_arrow_x = 410;
  const dc1_box_arrow_y = 400;

  const dc2_box_x = 575;
  const dc2_box_y = 525;
  const dc2_box_arrow_x = 490;
  const dc2_box_arrow_y = 400;

  return (
    <svg width="900" height="600">
        <image href="images/Fronius-Eco.png" x={450 - (img_size_x / 2)} y={350 - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
        <rect
          x={ac_box_x - (ac_box_size_x / 2)}
          y={ac_box_y - (ac_box_size_y / 2)}
          width={ac_box_size_x}
          height={ac_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
      <circle fill="#E96323" cx={ac_box_arrow_x} cy={ac_box_arrow_y} r="10" class="shadow"/>
      <path d={`M ${ac_box_arrow_x} ${ac_box_arrow_y} L ${ac_box_x} ${ac_box_y}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={ac_box_arrow_x} cy={ac_box_arrow_y} r="10"/>
      <rect
          x={ac_box_x - (ac_box_size_x / 2)}
          y={ac_box_y - (ac_box_size_y / 2)}
          width={ac_box_size_x}
          height={ac_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 15} y={ac_box_y - (ac_box_size_y / 2) + 50} text='Činný výkon:'/>
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 15} y={ac_box_y - (ac_box_size_y / 2) + 75} text='Proud AC:'/>
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 15} y={ac_box_y - (ac_box_size_y / 2) + 100} text='Účiník:'/>
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 15} y={ac_box_y - (ac_box_size_y / 2) + 125} text='Napětí AC:'/>

      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 150} y={ac_box_y - (ac_box_size_y / 2) + 15 + 14} text='1. FÁZE' anchor={"middle"}/>
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 225} y={ac_box_y - (ac_box_size_y / 2) + 15 + 14} text='2. FÁZE' anchor={"middle"}/>
      <WriteHeader x={ac_box_x - (ac_box_size_x / 2) + 300} y={ac_box_y - (ac_box_size_y / 2) + 15 + 14} text='3. FÁZE' anchor={"middle"}/>

      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 150} y={ac_box_y - (ac_box_size_y / 2) + 50} text={`${data.I_AC_PowerA===undefined ? 0 : data.I_AC_PowerA.toFixed(1)} kW`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 225} y={ac_box_y - (ac_box_size_y / 2) + 50} text={`${data.I_AC_PowerB===undefined ? 0 : data.I_AC_PowerB.toFixed(1)} kW`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 300} y={ac_box_y - (ac_box_size_y / 2) + 50} text={`${data.I_AC_PowerB===undefined ? 0 : data.I_AC_PowerC.toFixed(1)} kW`} anchor={"middle"}/>

      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 150} y={ac_box_y - (ac_box_size_y / 2) + 75} text={`${data.I_AC_CurrentA===undefined ? 0 : data.I_AC_CurrentA.toFixed(1)} A`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 225} y={ac_box_y - (ac_box_size_y / 2) + 75} text={`${data.I_AC_CurrentB===undefined ? 0 : data.I_AC_CurrentB.toFixed(1)} A`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 300} y={ac_box_y - (ac_box_size_y / 2) + 75} text={`${data.I_AC_CurrentC===undefined ? 0 : data.I_AC_CurrentC.toFixed(1)} A`} anchor={"middle"}/>

      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 225} y={ac_box_y - (ac_box_size_y / 2) + 100} text={data.I_AC_PF===undefined ? 0 : data.I_AC_PF.toFixed(2)} anchor={"middle"}/>

      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 150} y={ac_box_y - (ac_box_size_y / 2) + 125} text={`${data.I_AC_VoltageAN===undefined ? 0 : data.I_AC_VoltageAN.toFixed(1)} V`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 225} y={ac_box_y - (ac_box_size_y / 2) + 125} text={`${data.I_AC_VoltageBN===undefined ? 0 : data.I_AC_VoltageBN.toFixed(1)} V`} anchor={"middle"}/>
      <WriteValue x={ac_box_x - (ac_box_size_x / 2) + 300} y={ac_box_y - (ac_box_size_y / 2) + 125} text={`${data.I_AC_VoltageCN===undefined ? 0 : data.I_AC_VoltageCN.toFixed(1)} V`} anchor={"middle"}/>

      <rect
          x={dc1_box_x - (dc_box_size_x / 2)}
          y={dc1_box_y - (dc_box_size_y / 2)}
          width={dc_box_size_x}
          height={dc_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
        <circle fill="#E96323" cx={dc1_box_arrow_x} cy={dc1_box_arrow_y} r="10" class="shadow"/>
      <path d={`M ${dc1_box_arrow_x} ${dc1_box_arrow_y} L ${dc1_box_x} ${dc1_box_y}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={dc1_box_arrow_x} cy={dc1_box_arrow_y} r="10"/>
      <rect
          x={dc1_box_x - (dc_box_size_x / 2)}
          y={dc1_box_y - (dc_box_size_y / 2)}
          width={dc_box_size_x}
          height={dc_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={dc1_box_x - (dc_box_size_x / 2) + 15} y={dc1_box_y - (dc_box_size_y / 2) + 30} text='Činný výkon:'/>
      <WriteHeader x={dc1_box_x - (dc_box_size_x / 2) + 15} y={dc1_box_y - (dc_box_size_y / 2) + 55} text='Proud DC:'/>
      <WriteHeader x={dc1_box_x - (dc_box_size_x / 2) + 15} y={dc1_box_y - (dc_box_size_y / 2) + 80} text='Napětí DC:'/>

      <WriteValue x={dc1_box_x - (dc_box_size_x / 2) + 150} y={dc1_box_y - (dc_box_size_y / 2) + 30} text={`${data.synergyUnits === undefined || data.synergyUnits[0].DCW === undefined ? 0 :data.synergyUnits[0].DCW.toFixed(1)} kW`} anchor={"middle"}/>
      <WriteValue x={dc1_box_x - (dc_box_size_x / 2) + 150} y={dc1_box_y - (dc_box_size_y / 2) + 55} text={`${data.synergyUnits === undefined || data.synergyUnits[0].DCA === undefined ? 0 :data.synergyUnits[0].DCA.toFixed(1)} A`} anchor={"middle"}/>
      <WriteValue x={dc1_box_x - (dc_box_size_x / 2) + 150} y={dc1_box_y - (dc_box_size_y / 2) + 80} text={`${data.synergyUnits === undefined || data.synergyUnits[0].DCV === undefined ? 0 :data.synergyUnits[0].DCV.toFixed(1)} V`} anchor={"middle"}/>

      <rect
          x={dc2_box_x - (dc_box_size_x / 2)}
          y={dc2_box_y - (dc_box_size_y / 2)}
          width={dc_box_size_x}
          height={dc_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
        <circle fill="#E96323" cx={dc2_box_arrow_x} cy={dc2_box_arrow_y} r="10" class="shadow"/>
      <path d={`M ${dc2_box_arrow_x} ${dc2_box_arrow_y} L ${dc2_box_x} ${dc2_box_y}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={dc2_box_arrow_x} cy={dc2_box_arrow_y} r="10"/>
      <rect
          x={dc2_box_x - (dc_box_size_x / 2)}
          y={dc2_box_y - (dc_box_size_y / 2)}
          width={dc_box_size_x}
          height={dc_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={dc2_box_x - (dc_box_size_x / 2) + 15} y={dc2_box_y - (dc_box_size_y / 2) + 30} text='Činný výkon:'/>
      <WriteHeader x={dc2_box_x - (dc_box_size_x / 2) + 15} y={dc2_box_y - (dc_box_size_y / 2) + 55} text='Proud DC:'/>
      <WriteHeader x={dc2_box_x - (dc_box_size_x / 2) + 15} y={dc2_box_y - (dc_box_size_y / 2) + 80} text='Napětí DC:'/>

      <WriteValue x={dc2_box_x - (dc_box_size_x / 2) + 150} y={dc2_box_y - (dc_box_size_y / 2) + 30} text={`${data.synergyUnits === undefined || data.synergyUnits[1].DCW === undefined ? 0 :data.synergyUnits[1].DCW.toFixed(1)} kW`} anchor={"middle"}/>
      <WriteValue x={dc2_box_x - (dc_box_size_x / 2) + 150} y={dc2_box_y - (dc_box_size_y / 2) + 55} text={`${data.synergyUnits === undefined || data.synergyUnits[1].DCA === undefined ? 0 :data.synergyUnits[1].DCA.toFixed(1)} A`} anchor={"middle"}/>
      <WriteValue x={dc2_box_x - (dc_box_size_x / 2) + 150} y={dc2_box_y - (dc_box_size_y / 2) + 80} text={`${data.synergyUnits === undefined || data.synergyUnits[1].DCV === undefined ? 0 :data.synergyUnits[1].DCV.toFixed(1)} V`} anchor={"middle"}/>
    </svg>
  );
};

function DrawFroniusTemp(props)
{
  const data = props.INV;

  const img_size_x = 250;
  const img_size_y = 250;

  const info_box_size_x = 150;
  const info_box_size_y = 80;
  const x_box = 450;
  const y_box = 125;

  return (
    <svg width="900" height="600">
      <image href="images/Fronius-Eco.png" x={450 - (img_size_x / 2)} y={350 - (img_size_y / 2)} width={img_size_x} height={img_size_y} class="shadow" />
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
          class="shadow"
        />
      <circle fill="#E96323" cx={450} cy={350} r="10" class="shadow"/>
      <path d={`M ${450} ${350} L ${x_box} ${y_box}`} stroke="#E96323" fill="transparent" class="shadow" strokeWidth="4"/>
      <circle fill="#E96323" cx={450} cy={350} r="10"/>
      <rect
          x={x_box - (info_box_size_x / 2)}
          y={y_box - (info_box_size_y / 2)}
          width={info_box_size_x}
          height={info_box_size_y} // Výška boxu
          fill="rgba(0, 0, 0, 1)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
      <WriteHeader x={x_box + (info_box_size_x / 2) - 10} y={y_box + 10} text={`${data.I_Temp_Sink === undefined ? 0 : data.I_Temp_Sink.toFixed(1)} °C`} anchor="end" size={28}/>

      <circle fill="#d51317" cx={x_box - (info_box_size_x / 2) + 20} cy={y_box + (info_box_size_y / 2) - 20} r="10"/>
      <path d={`M ${x_box - (info_box_size_x / 2) + 20} ${y_box + (info_box_size_y / 2) - 20} L ${x_box - (info_box_size_x / 2) + 20} ${y_box - (info_box_size_y / 2) + 10}`} stroke="#d51317" fill="transparent" strokeWidth="4"/>  
    </svg>
  );
};

export {FroniusEco25InverterSmall, FroniusSymo20InverterSmall, DrawFroniusOverview, DrawFroniusTemp};