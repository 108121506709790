import {FroniusEco25InverterSmall, FroniusSymo20InverterSmall, DrawFroniusOverview, DrawFroniusTemp} from "./fronius";
import {SE100KInverterSmall, DrawSE100KOverview, DrawSE100KTemp} from "./se100k";
import {SE50KInverterSmall, DrawSE50KOverview, DrawSE50KTemp} from "./se50k";
import {WriteHeader, WriteValue, ErrorTable, LimitationTable, RestTable} from "./common.js"
import {SE17KInverterSmall, DrawSE17KOverview, DrawSE17KTemp} from "./se17k.js"
import './inverters.css'

function DrawError(props)
{

  return (
    <div className='invert-modal-error-tab-content inverter-modal-table-style'>
      <div className="inverter-modal-error-tab-actual">
        <h1>Aktuální chyba</h1>
        <div className="inverter-modal-error-tab-actual-name"><b>Název:</b> {props.INV.I_Status_Vendor === 0 ? "Bez chyby" : props.INV.I_Status_Vendor}</div>
        <div className="inverter-modal-error-tab-actual-solution"><b>Řešení:</b></div>
      </div>
      <div className="inverter-modal-error-tab-history">
        <h1>Historie chyb</h1>
        <div className="inverter-modal-error-tab-history-table"><ErrorTable /></div>
      </div>
    </div>
  );
};

function DrawAll(props)
{

  return (
    <div className='inverter-modal-all-tab-content inverter-modal-table-style'>
      <div className="inverter-modal-all-tab-limitation">
        <h1>Aktuální limitace</h1>
        <div className="inverter-modal-all-tab-limitation-table"><LimitationTable INV={ props.INV } /></div>
      </div>
      <div className="inverter-modal-all-tab-rest">
        <h1>Ostatní hodnoty</h1>
        <div className="inverter-modal-all-tab-rest-table"><RestTable INV={ props.INV } /></div>
      </div>
    </div>
  );
};

function DrawOverview(props)
{
  if(props.INV.Device_Vendor.includes("SolarEdge"))
  {
    if(props.INV.Device_Model.includes('SE50K'))
      return (<DrawSE50KOverview INV={props.INV} />);
    else if(props.INV.Device_Model.includes('SE100K'))
      return (<DrawSE100KOverview INV={props.INV} />);
    else if(props.INV.Device_Model.includes('SE17K'))
      return (<DrawSE17KOverview INV={props.INV} />);
  }
  else if(props.INV.Device_Vendor === "Fronius")
  {
    return (<DrawFroniusOverview INV={props.INV} />);
  }
};

function DrawTemp(props)
{
  if(props.INV.Device_Vendor.includes("SolarEdge"))
  {
    if(props.INV.Device_Model.includes('SE50K'))
      return (<DrawSE50KTemp INV={props.INV} />);
    else if(props.INV.Device_Model.includes('SE100K'))
      return (<DrawSE100KTemp INV={props.INV} />);
    else if(props.INV.Device_Model.includes('SE17K'))
      return (<DrawSE17KTemp INV={props.INV} />);
  }
  else if(props.INV.Device_Vendor === "Fronius")
  {
    return (<DrawFroniusTemp INV={props.INV} />);
  }
};

function SelectInverter(invData, onClick)
{
    const invNumber = Number(invData.Device_ID.replace('INV', ''));

    if(invData.Device_Vendor === "SolarEdge")
    {
      if(invData.Device_Model.includes('SE50K'))
        return (<SE50KInverterSmall INV={invData} number={invNumber} onClick={() => onClick(invNumber)} />);
      else if(invData.Device_Model.includes('SE100K'))
        return (<SE100KInverterSmall INV={invData} number={invNumber} onClick={() => onClick(invNumber)} />);
      else if(invData.Device_Model.includes('SE17K'))
        return (<SE17KInverterSmall INV={invData} number={invNumber} onClick={() => onClick(invNumber)} />);
    }
    else if(invData.Device_Vendor === "Fronius")
    {
      if(invData.Device_Model.includes('Eco 25'))
        return (<FroniusEco25InverterSmall INV={invData} number={invNumber} onClick={() => onClick(invNumber)} />);
      else if(invData.Device_Model.includes('Symo 20'))
        return (<FroniusSymo20InverterSmall INV={invData} number={invNumber} onClick={() => onClick(invNumber)} />);
    }
}


export {DrawAll, DrawError, DrawOverview, DrawTemp, SelectInverter};