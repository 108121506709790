import React, { useState } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import DatePicker from "react-datepicker"; // npm install react-datepicker
import "react-datepicker/dist/react-datepicker.css";
import "./test.css";

// Font Awesome (npm install @fortawesome/react-fontawesome @fortawesome/free-solid-svg-icons @fortawesome/fontawesome-svg-core)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTree, faMoneyBill, faBolt, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

/* 
  PŘÍPRAVA FONTU ROBOTO:
  V index.html / nebo v CSS:
  <link rel="preconnect" href="https://fonts.gstatic.com" />
  <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet" />
*/

/* ===================================================================
   1) MOCKOVÁ DATA – VĚTŠÍ ROZSAH
      - V "Historická" data budeme mít víc týdnů/měsíců, 
        abychom viděli "reálnější" chování.
=================================================================== */

// Data pro "Aktuální" (hodinový) graf. Včetně data, abychom ukázali dny v týdnu.
const currentDayData = [
  { date: "2024-10-01T00:00:00", co2: 0.5 },
  { date: "2024-10-01T01:00:00", co2: 0.8 },
  { date: "2024-10-01T02:00:00", co2: 1.2 },
  // ... pro zkrácení vynechány další hodiny ...
  { date: "2024-10-01T23:00:00", co2: 0.6 },
];

// Data pro „Historický“ graf (třeba denní). 
// Každá položka má "date", "co2Saved" a "costSaved".
const longHistoricalData = [
  { date: "2024-09-01", co2Saved: 2.0, costSaved: 120 },
  { date: "2024-09-02", co2Saved: 2.1, costSaved: 125 },
  { date: "2024-09-03", co2Saved: 1.8, costSaved: 110 },
  // ... zvětšeno až na 30+ dnů:
  { date: "2024-09-28", co2Saved: 3.5, costSaved: 180 },
  { date: "2024-09-29", co2Saved: 3.7, costSaved: 190 },
  { date: "2024-09-30", co2Saved: 4.0, costSaved: 200 },
  { date: "2024-10-01", co2Saved: 4.2, costSaved: 210 },
  { date: "2024-10-02", co2Saved: 3.9, costSaved: 190 },
];

// Data pro dlaždice – sumy / průměry.
const historicalTiles = [
  {
    label: "Průměrná denní úspora",
    value: "1.8 t CO₂",
    description: "Průměr za poslední 30 dní"
  },
  {
    label: "Průměrná týdenní úspora",
    value: "12.6 t CO₂",
    description: "Průměr za posledních 8 týdnů"
  },
  {
    label: "Průměrná měsíční úspora",
    value: "53 t CO₂",
    description: "Průměr za poslední 12 měsíců"
  },
  {
    label: "Roční průměr",
    value: "289 t CO₂",
    description: "Za posledních 12 měsíců"
  },
];

/* Sumární statistiky pro "aktuální stav" */
const initialStats = {
  totalCO2: 15.2,         // t CO₂
  totalTrees: 58,         // ekvivalent stromů
  totalCostSaved: 12000,  // Kč
  totalKwhSaved: 3400,    // kWh
  // Trendy oproti včerejšku (pro ukázku):
  trendCO2: +5,           // +5 % 
  trendTrees: +3,         
  trendCost: -2,          // -2 % -> pokles
  trendKwh: +1
};

// Pro pokročilé filtry – default:
const defaultFilterState = {
  startDate: new Date("2024-09-01"),
  endDate: new Date("2024-10-05"),
  onlyWeekdays: false
};

/* ===================================================================
   2) HLAVNÍ KOMPONENTA
=================================================================== */
function AdvancedEmissionsPage() {
  // ------------- Stavy -------------
  // Stavy pro tabs (horizontální menu)
  const [activeTab, setActiveTab] = useState("current"); // "current" | "history"

  // Stav pro typ grafu (sloupcový vs. čárový) v Historické sekci
  const [chartType, setChartType] = useState("bar"); // "bar" | "line"

  // Stav pro statistiky (aktuální)
  const [stats] = useState(initialStats);

  // Stav pro historická data
  const [historicalData, setHistoricalData] = useState(longHistoricalData);

  // Stav pro pokročilé filtry
  const [filters, setFilters] = useState(defaultFilterState);

  // Stav pro legendu (zda zobrazovat co2Saved, costSaved)
  const [showCO2, setShowCO2] = useState(true);
  const [showCost, setShowCost] = useState(true);

  // Stav pro zobrazení modálu (detail analýzy)
  const [showModal, setShowModal] = useState(false);

  // ------------- Funkce -------------
  // Přepnutí tabů
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Tlačítko pro přepnutí typu grafu (sloupcový vs. čárový)
  const handleChartTypeToggle = () => {
    setChartType((prev) => (prev === "bar" ? "line" : "bar"));
  };

  // Interaktivní legendy – kliknutím skrýváme/ukazujeme datovou řadu
  const handleLegendClick = (e) => {
    if (e.dataKey === "co2Saved") {
      setShowCO2(!showCO2);
    } else if (e.dataKey === "costSaved") {
      setShowCost(!showCost);
    }
  };

  // Pokročilé filtry – např. "Pouze pracovní dny"
  const applyFilters = () => {
    let filtered = longHistoricalData.filter((d) => {
      const dateObj = new Date(d.date);
      // Filtr dle start/end
      if (dateObj < filters.startDate || dateObj > filters.endDate) {
        return false;
      }
      // Filtr "Pouze pracovní dny"
      if (filters.onlyWeekdays) {
        const day = dateObj.getDay(); // 0 = Neděle, 6 = Sobota
        if (day === 0 || day === 6) {
          return false;
        }
      }
      return true;
    });
    setHistoricalData(filtered);
  };

  // ------------- Pomocné funkce pro formátování -------------
  // Vrátí např. "Út 05.09."
  const formatDateWithWeekday = (isoString) => {
    const date = new Date(isoString);
    const dayNames = ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"];
    const dow = dayNames[date.getDay()] || "";
    // Např. 05.09.
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    return `${dow} ${dd}.${mm}.`;
  };

  // ------------- VLASTNÍ TOOLTIP -------------
  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || payload.length === 0) return null;
    // V label máme iso datum
    const dateStr = formatDateWithWeekday(label); 
    // Např. CO₂ a cost
    const co2Item = payload.find((p) => p.dataKey === "co2Saved");
    const costItem = payload.find((p) => p.dataKey === "costSaved");
    return (
      <div className="custom-tooltip">
        <h4>{dateStr}</h4>
        {co2Item && (
          <p style={{ color: co2Item.stroke || co2Item.fill }}>
            CO₂: {co2Item.value} t
          </p>
        )}
        {costItem && (
          <p style={{ color: costItem.stroke }}>
            Náklady: {costItem.value} Kč
          </p>
        )}
      </div>
    );
  };

  // ------------- MODÁL -------------
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="advanced-page-container">
      {/* Horizontální menu (tabs) */}
      <div className="tab-menu">
        <button
          className={activeTab === "current" ? "tab-active" : ""}
          onClick={() => handleTabChange("current")}
        >
          Aktuální stav
        </button>
        <button
          className={activeTab === "history" ? "tab-active" : ""}
          onClick={() => handleTabChange("history")}
        >
          Historická data
        </button>
      </div>

      {activeTab === "current" && (
        <div className="tab-content">
          {/*  ==================  SEKCE: AKTUÁLNÍ STAV  ==================  */}
          <h2 className="section-title">Aktuální stav</h2>

          {/* Trend indikátory – v rámci stat-boxů */}
          <div className="stats-row">
            <div className="stat-box">
              <div className="stat-icon">
                <FontAwesomeIcon icon={faTree} />
              </div>
              <div className="stat-value">
                {stats.totalCO2} t CO₂{" "}
                {stats.trendCO2 >= 0 ? (
                  <span className="trend-up">
                    <FontAwesomeIcon icon={faArrowUp} /> {stats.trendCO2}%
                  </span>
                ) : (
                  <span className="trend-down">
                    <FontAwesomeIcon icon={faArrowDown} /> {Math.abs(stats.trendCO2)}%
                  </span>
                )}
              </div>
              <div className="stat-label">Celková úspora</div>
            </div>

            <div className="stat-box">
              <div className="stat-icon">
                <FontAwesomeIcon icon={faTree} />
              </div>
              <div className="stat-value">
                {stats.totalTrees}{" "}
                {stats.trendTrees >= 0 ? (
                  <span className="trend-up">
                    <FontAwesomeIcon icon={faArrowUp} /> {stats.trendTrees}%
                  </span>
                ) : (
                  <span className="trend-down">
                    <FontAwesomeIcon icon={faArrowDown} /> {Math.abs(stats.trendTrees)}%
                  </span>
                )}
              </div>
              <div className="stat-label">Vysazených stromů</div>
            </div>

            <div className="stat-box">
              <div className="stat-icon">
                <FontAwesomeIcon icon={faMoneyBill} />
              </div>
              <div className="stat-value">
                {stats.totalCostSaved} Kč{" "}
                {stats.trendCost >= 0 ? (
                  <span className="trend-up">
                    <FontAwesomeIcon icon={faArrowUp} /> {stats.trendCost}%
                  </span>
                ) : (
                  <span className="trend-down">
                    <FontAwesomeIcon icon={faArrowDown} /> {Math.abs(stats.trendCost)}%
                  </span>
                )}
              </div>
              <div className="stat-label">Finanční úspora</div>
            </div>

            <div className="stat-box">
              <div className="stat-icon">
                <FontAwesomeIcon icon={faBolt} />
              </div>
              <div className="stat-value">
                {stats.totalKwhSaved} kWh{" "}
                {stats.trendKwh >= 0 ? (
                  <span className="trend-up">
                    <FontAwesomeIcon icon={faArrowUp} /> {stats.trendKwh}%
                  </span>
                ) : (
                  <span className="trend-down">
                    <FontAwesomeIcon icon={faArrowDown} /> {Math.abs(stats.trendKwh)}%
                  </span>
                )}
              </div>
              <div className="stat-label">Ušetřená energie</div>
            </div>
          </div>

          <div className="icon-section">
            <FontAwesomeIcon icon={faTree} className="tree-icon" />
            <p className="icon-text">
              Díky úspoře CO₂ jste pomyslně „vysadili“ {stats.totalTrees} stromů.
            </p>
            <button className="btn-detail" onClick={openModal}>
              Detailní analýza
            </button>
          </div>

          {/* Animovaná timeline – jen naznačeno (v praxi by se posouvala) */}
          <div className="timeline-container">
            <div className="timeline-track">
              <div className="timeline-handle">●</div>
            </div>
            <p className="timeline-label">Posouvejte časem (ukázka animace)</p>
          </div>

          {/* (Hodinový) graf s datem, kde na ose X zobrazujeme den v týdnu + hodinu */}
          <div className="chart-section">
            <h3>Hodinový přehled CO₂ (aktuální)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={currentDayData} 
                margin={{ top: 20, right: 20, bottom: 20, left: 0 }} 
                // Při mountu animace
                barCategoryGap="30%"
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis
                  dataKey="date"
                  stroke="#ccc"
                  tickFormatter={(val) => {
                    // Např. "Po 01:00"
                    const d = new Date(val);
                    const dayNames = ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"];
                    const dow = dayNames[d.getDay()] || "";
                    const hh = String(d.getHours()).padStart(2, "0");
                    return `${dow} ${hh}:00`;
                  }}
                />
                <YAxis stroke="#ccc" />
                <Tooltip
                  contentStyle={{ backgroundColor: "#2b2b2b", border: "none" }}
                  labelStyle={{ color: "#fff" }}
                  itemStyle={{ color: "#fff" }}
                  formatter={(value) => [`${value} kg`, "CO₂"]}
                />
                <Bar
                  dataKey="co2"
                  fill="#f57c00"
                  name="CO₂ (kg/hod)"
                  animationBegin={0}
                  animationDuration={1000}
                />
              </BarChart>
            </ResponsiveContainer>
            <p className="chart-description">
              Zobrazuje hodinovou úsporu CO₂ za dnešní den (ukázková data).
            </p>
          </div>
        </div>
      )}

      {activeTab === "history" && (
        <div className="tab-content">
          {/*  ==================  SEKCE: HISTORICKÁ DATA  ==================  */}
          <h2 className="section-title">Historická data</h2>

          {/* Pokročilé filtry */}
          <div className="advanced-filters">
            <div>
              <label>Od: </label>
              <DatePicker
                selected={filters.startDate}
                onChange={(date) => setFilters({ ...filters, startDate: date })}
                dateFormat="dd.MM.yyyy"
              />
              <label style={{ marginLeft: "1rem" }}>Do: </label>
              <DatePicker
                selected={filters.endDate}
                onChange={(date) => setFilters({ ...filters, endDate: date })}
                dateFormat="dd.MM.yyyy"
              />
            </div>
            <div>
              <label style={{ marginRight: "0.5rem" }}>
                <input
                  type="checkbox"
                  checked={filters.onlyWeekdays}
                  onChange={(e) =>
                    setFilters({ ...filters, onlyWeekdays: e.target.checked })
                  }
                />
                Pouze pracovní dny
              </label>
              <button onClick={applyFilters} className="btn-apply">
                Aplikovat
              </button>
            </div>
          </div>

          {/* Dlaždice se sumami / průměry */}
          <div className="history-tiles-row">
            {historicalTiles.map((tile, idx) => (
              <div key={idx} className="history-tile">
                <div className="tile-value">{tile.value}</div>
                <div className="tile-label">{tile.label}</div>
                <div className="tile-desc">{tile.description}</div>
              </div>
            ))}
          </div>

          {/* Ovládací panel pro typ grafu a legendu */}
          <div className="chart-controls">
            <button onClick={handleChartTypeToggle} className="btn-chart-toggle">
              Přepnout na {chartType === "bar" ? "Line" : "Bar"} Chart
            </button>
          </div>

          {/* Graf s legendou, interaktivní skrývání */}
          <div className="chart-section">
            <h3>Historie úspor CO₂</h3>
            <ResponsiveContainer width="100%" height={350}>
              {chartType === "bar" ? (
                <ComposedChart
                  data={historicalData}
                  margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis
                    dataKey="date"
                    stroke="#ccc"
                    tickFormatter={(val) => formatDateWithWeekday(val)}
                  />
                  <YAxis yAxisId="left" stroke="#ccc" />
                  <YAxis yAxisId="right" orientation="right" stroke="#ccc" />
                  {/* Vlastní tooltip */}
                  <Tooltip content={<CustomTooltip />} />
                  <Legend onClick={handleLegendClick} />
                  {/* Sloupce pro CO₂ */}
                  {showCO2 && (
                    <Bar
                      yAxisId="left"
                      dataKey="co2Saved"
                      fill="#f57c00"
                      maxBarSize={30}
                      name="CO₂ (t)"
                      animationBegin={0}
                      animationDuration={800}
                    />
                  )}
                  {/* Linie pro finanční úsporu */}
                  {showCost && (
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="costSaved"
                      stroke="#76d275"
                      strokeWidth={3}
                      name="Úspora (Kč)"
                      dot={false}
                      animationBegin={0}
                      animationDuration={800}
                    />
                  )}
                </ComposedChart>
              ) : (
                /* LineChart varianta */
                <LineChart
                  data={historicalData}
                  margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis
                    dataKey="date"
                    stroke="#ccc"
                    tickFormatter={(val) => formatDateWithWeekday(val)}
                  />
                  <YAxis stroke="#ccc" />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend onClick={handleLegendClick} />
                  {showCO2 && (
                    <Line
                      type="monotone"
                      dataKey="co2Saved"
                      stroke="#f57c00"
                      strokeWidth={3}
                      name="CO₂ (t)"
                      dot={true}
                      animationBegin={0}
                      animationDuration={800}
                    />
                  )}
                  {showCost && (
                    <Line
                      type="monotone"
                      dataKey="costSaved"
                      stroke="#76d275"
                      strokeWidth={3}
                      name="Úspora (Kč)"
                      dot={true}
                      animationBegin={0}
                      animationDuration={800}
                    />
                  )}
                </LineChart>
              )}
            </ResponsiveContainer>
            <p className="chart-description">
              Sloupce/čáry představují úsporu emisí CO₂, zatímco zelená křivka
              znázorňuje finanční úsporu. Kliknutím v legendě můžete
              skrýt/zobrazit jednotlivé řady.
            </p>
          </div>
        </div>
      )}

      {/* MODÁL S DETAILNÍ ANALÝZOU */}
      {showModal && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Detailní analýza</h3>
            <p>
              Zde můžete zobrazit pokročilé statistiky, grafy, tabulky 
              nebo cokoliv, co dává smysl vašemu projektu.
            </p>
            <button onClick={closeModal} className="btn-close">
              Zavřít
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvancedEmissionsPage;
