import './uniTooltip.css';
import {formatCzechTimestamp} from '../../utils/Date.js'

const UniversalTooltip = ({ active, payload, label }) => {
    if (active && payload) {
        
        let sum = 0;

        const allTooltipLines = [];

        for(let i = 0; i < payload.length; i++)
        {
            allTooltipLines.push(<p className="data" style={{color: payload[i].fill}}><b>{payload[i].name}:</b> {payload[i].value.toFixed(1)} {payload[i].unit}</p>);
            sum += Number(payload[i].value);
        }

        if (payload.length > 1)
        {
            allTooltipLines.push(<p className="data" style={{color: "#C5C5C5"}}><b>Celkem:</b> {sum.toFixed(1)} {payload[0].unit}</p>);
        }

        return (
            <div className="universal-tooltip">
                <p className="label">{formatCzechTimestamp(label)}</p>
                {allTooltipLines}
            </div>
        );
    }
};

export default UniversalTooltip;
    