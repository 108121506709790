import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';

const AdvancedOptions = ({
  selectedYear,
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
  years,
  months,
  selectedType,
  loading,
  toast,
}) => {
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [isMonthOpen, setIsMonthOpen] = useState(false);

  const selectStyles = {
    background: '#FFFFFF',
    boxShadow:
      'inset 0px 3px 1px rgba(233, 99, 35, 0.25), 0px 3px 6px rgba(0, 0, 0, 0.16)',
    border: '1px solid #707070',
    borderRadius: '10px',
    '.MuiSelect-select': {
      padding: '10px',
    },
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E96323',
    },
    '& .MuiSelect-icon': {
      color: '#E96323',
    },
  };

  const menuStyles = {
    '& .MuiMenuItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#E96323',
        color: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: '#d5581f',
        color: '#FFFFFF',
      },
    },
  };

  return (
    <Grid container spacing={2}>
      {/* Select Year */}
      <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
  {!selectedYear && (
    <InputLabel
      id="year-label"
      sx={{
        color: '#666666',
        '&.Mui-focused': { color: '#E96323' },
      }}
    >
      Vyberte rok
    </InputLabel>
  )}

  <Tooltip
    title={!selectedYear && !isYearOpen ? "Vyberte rok pro report." : ""}
    arrow
  >
    <Select
      labelId="year-label"
      id="year-select"
      value={selectedYear}
      label="Rok"
      disabled={loading}
      onChange={(e) => setSelectedYear(e.target.value)}
      onOpen={() => setIsYearOpen(true)}
      onClose={() => setIsYearOpen(false)}
      sx={selectStyles}
      MenuProps={{ sx: menuStyles }}
    >
      {!selectedYear && (
        <MenuItem value="">
          <em>Vyberte rok</em>
        </MenuItem>
      )}
      {years.map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  </Tooltip>
</FormControl>
      </Grid>

      {/* Select Month */}
      {selectedType === 'monthly' && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel
              id="month-label"
              sx={{
                color: '#666666',
                '&.Mui-focused': { color: '#E96323' },
              }}
            >
              Vyberte měsíc
            </InputLabel>

            <Tooltip
              title={!selectedMonth && !isMonthOpen ? "Vyberte měsíc pro report." : ""}
              arrow
            >
              <Select
                labelId="month-label"
                id="month-select"
                value={selectedMonth}
                label="Měsíc"
                disabled={loading}
                onChange={(e) => setSelectedMonth(e.target.value)}
                onOpen={() => setIsMonthOpen(true)}
                onClose={() => setIsMonthOpen(false)}
                sx={selectStyles}
                MenuProps={{ sx: menuStyles }}
              >
                <MenuItem value="">
                  <em>Vyberte měsíc</em>
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default AdvancedOptions;
