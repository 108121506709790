import React, { useState, useEffect } from 'react';
import patchNotes from './patchNotes.json';
import './Footer.css';

function Footer() {
  const [showModal, setShowModal] = useState(false);

  // Ukládáme, které verze (např. "1.2", "1.1", ...) jsou otevřené (rozbalené).
  // Může to být objekt typu: { "1.2": true, "1.1": false } apod.
  const [expandedVersions, setExpandedVersions] = useState({});

  // Přidáme posluchač kláves při otevření modalu (pro zavření pomocí ESC)
  useEffect(() => {
    if (!showModal) return;

    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setShowModal(false);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [showModal]);

  // Funkce pro otevření/zavření modalu
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  // Zavírá modal kliknutím do pozadí (mimo obsah)
  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowModal(false);
    }
  };

  /**
   * Toggle pro jednu konkrétní verzi patchNotes
   * Když uživatel klikne na "Verze 1.2 ...", chceme rozbalit nebo sbalit detaily této verze
   */
  const toggleVersion = (version) => {
    setExpandedVersions((prevState) => ({
      ...prevState,
      [version]: !prevState[version], // Otočí stav true/false
    }));
  };

  return (
    <footer className="footer">
      © 2024 METRICA&nbsp;&nbsp;/&nbsp;&nbsp;YOUNG4SERVICES s.r.o.&nbsp;&nbsp;|&nbsp;&nbsp;Powered by YOUNG4ENERGY s.r.o.
      <div className="version" onClick={toggleModal}>
        Verze aplikace: {patchNotes.version}
      </div>

      {/* Modal pro Patch Notes */}
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content animate-modal">
            <h2>Patch Notes</h2>
            <div className="modal-body">
              {patchNotes.patchNotes.map((note) => {
                // Zjistíme, zda je daná verze aktuálně rozbalená nebo ne:
                const isExpanded = !!expandedVersions[note.version];

                return (
                  <div key={note.version} className="patch-note-section">
                    {/* Hlavička verze - kliknutím rozbalí/sbalí detail */}
                    <div 
                      className="patch-note-header" 
                      onClick={() => toggleVersion(note.version)}
                    >
                      <h3>
                        Verze {note.version} - {note.date}
                      </h3>
                      {/* Symbol indikující rozbalení/sbalení */}
                      <span className="expand-indicator">
                        {isExpanded ? '[-]' : '[+]'}
                      </span>
                    </div>

                    {/* Vlastní obsah verze zobrazíme jen pokud isExpanded === true */}
                    {isExpanded && (
                      <div className="patch-note-content">
                        <h4 className="changes-title">Změny:</h4>
                        <ul>
                          {note.changes.map((change, index) => {
                            // Rozdělíme text na "Něco:" a zbytek
                            const [boldText, normalText] = change.split(':');
                            return (
                              <li key={index}>
                                <strong>{boldText}:</strong>
                                <span className="normal-text">{normalText}</span>
                              </li>
                            );
                          })}
                        </ul>
                        {note.fixes && note.fixes.length > 0 && (
                          <>
                            <h4 className="fixes-title">Opravy:</h4>
                            <ul>
                              {note.fixes.map((fix, index) => {
                                const [boldText, normalText] = fix.split(':');
                                return (
                                  <li key={index}>
                                    <strong>{boldText}:</strong>
                                    <span className="normal-text">{normalText}</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <button onClick={toggleModal}>Zavřít</button>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
