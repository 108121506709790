import "./fveOverviewCard.css";
import { Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';
import { formatTimeFromTimestamp } from '../../utils/Date.js';
import UniversalTooltip from '../uniTooltip/uniTooltip.js';
import { useId } from 'react';

function BarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#7dba00" stopOpacity="1"/>
          <stop offset="100%" stopColor="#7dba00" stopOpacity="0.2" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

function ActiveBarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#d51317" stopOpacity="1"/>
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

function PrepareDataFromINV(INV, FVE_Name)
{
  const status = { 0:0, 1:0, 2:0, 3:0, 4:0, 5:0, 6:0, 7:0, 8:0 };
  const data = {statusString: 'Načítám...', power: 0, regulation: 0, status: 2};
  let invCount = 0;


  for(const key in INV)
  {
    if(INV[key].FVE_Name !== FVE_Name)
      continue;

    invCount++;
    data.power += INV[key].I_AC_Power;
    data.regulation += INV[key].activePowerLimit;

    if(INV[key].I_Status === undefined || INV[key].I_Status < 1 || INV[key].I_Status > 8)
    {
      status[0]++;
    }
    else
    {
      status[INV[key].I_Status]++;
    }
  }

  data.regulation /= invCount;

  if(status[7] > 0)
    data.status=0;

  const allMap = {
    0: "Neznámý",
    1: "Vypnuto",
    2: "Noční režim",
    3: "Probouzení",
    4: "Výroba",
    5: "Výroba s omezením",
    6: "Vypínání",
    7: "Chyba",
    8: "Údržba"
  };

  let nonZeroCount = 0;

  for(const key in status)
  {
    if(status[key] > 0)
    {
      nonZeroCount++;
    }
  }

  if(nonZeroCount === 1)
  {
    for(const key in status)
    {
      if(status[key] > 0)
      {
        data.statusString = allMap[key];
        break;
      }
    }
  }
  else if(nonZeroCount > 1)
  {
    let statusString = '';

    for(const key in status)
    {
      if(status[key] === 0)
        continue;

      if(statusString === '')
      {
        statusString += `${allMap[key]}: ${status[key]}`;
      }
      else
      {
        statusString += `; ${allMap[key]}: ${status[key]}`;
      }
    }

    data.statusString = statusString;
  }

  return data;
}

function FVEOverviewCard(props)
{
  const prepraredDataFromINV = PrepareDataFromINV(props.INV, props.FVE_Name);
  const powerLevel = prepraredDataFromINV.power || 0;
  const maxPower = props.max;
  const powerLevelWidth = `${(powerLevel / maxPower) * 100}%`;

  return(
    <div className='fve-card'>
      <div className='fve-card-grid'>
        <div className='fve-card-half1'>
          <div className="fve-card-top-bar">
            <h2>{props.name}</h2>
            {prepraredDataFromINV.status === 2 && <img src="/images/ikona_ok_50x50.png" alt="OK" />}
            {prepraredDataFromINV.status === 0 && <img className='fve-card-error' src="/images/ikona_pruser_50x50.png" alt="OK" />}
          </div>
          <div className='fve-card-stats'>
            <p><strong>Výroba dnes:</strong> {props.stats.day !== undefined ? `${props.stats.day} kWh` : 'Načítám...'}</p>
            <p><strong>Výroba tento měsíc:</strong> {props.stats.month !== undefined ? `${props.stats.month} MWh` : 'Načítám...'}</p>
            <p><strong>Výroba tento rok:</strong> {props.stats.year !== undefined ? `${props.stats.year} MWh` : 'Načítám...'}</p>
            <p><strong>Výroba celkem:</strong> {props.stats.life !== undefined ? `${props.stats.life} MWh` : 'Načítám...'}</p>
          </div>
          <div className='fve-card-stats'>
            <p><strong>Status:</strong> {prepraredDataFromINV.statusString}</p>
            {props.stats.spotRegulation !== undefined && <p><strong>Spotová regulace:</strong> {100 - props.stats.spotRegulation}%</p>}
            {props.stats.dispRegulation !== undefined && <p><strong>Dispečerská regulace:</strong> {100 - props.stats.dispRegulation}%</p>}
            {props.stats.spotRegulation === undefined && props.stats.dispRegulation === undefined && prepraredDataFromINV.regulation !== undefined && <p><strong>Regulace:</strong> {100 - prepraredDataFromINV.regulation}%</p>}
          </div>
          <div className="fve-card-power-container">
            <div className="fve-card-power-info">AKTUÁLNÍ VÝKON:</div>
            <div className="fve-card-power-bar">
              <div className="fve-card-power-level" style={{ width: powerLevelWidth }}></div>
              <div className="fve-card-power-value">{powerLevel.toFixed(1)} kW</div>
            </div>
            <div className="fve-card-power-scale">
              <span>0 kW</span>
              <span>{props.max / 2} kW</span>
              <span>{props.max} kW</span>
            </div>
          </div>
        </div>
        <div className='fve-card-half2'>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={300}
              data={props.graph}
            >
              <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
              <XAxis dataKey="name" tickFormatter={(tick)=> formatTimeFromTimestamp(tick)}/>
              <YAxis unit="kWh" />
              <Tooltip content={<UniversalTooltip />}/>
              <Bar type="monotone" name="Vyrobeno" unit="kWh" dataKey={props.graphKey} fill="#7dba00" shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      
    </div>
  );
}

export default FVEOverviewCard;