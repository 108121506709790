import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
} from '@mui/material';
import ContentPasteGoSharpIcon from '@mui/icons-material/ContentPasteGoSharp';

const ReportFormatSelector = ({ reportFormat, setReportFormat, loading }) => {
  // Define styles for the radio buttons
  const radioStyles = {
    '& .MuiSvgIcon-root': {
      borderRadius: '50%', // Kulaté okraje
      backgroundColor: '#ffffff', // Výplň bílé barvy
      color: '#e96323', // Oranžová barva prstence
      fontSize: '20px', // Velikost ikony (zmenšení)
      transition: 'transform 0.2s ease', // Animace pro plynulý přechod
    },
    '&.Mui-checked': {
      color: '#e96323', // Barva prstence po výběru
      '& .MuiSvgIcon-root': {
        fontSize: '22px', // Zvýšení velikosti oranžového bodu při výběru
      },
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Zarovnání nadpisu nad radio buttony
        alignItems: 'flex-start', // Zarovnání vlevo
        gap: '8px', // Mezera mezi nadpisem a radio buttony
      }}
    >
      {/* Icon and Heading */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <ContentPasteGoSharpIcon sx={{ color: '#E96323', fontSize: '24px' }} />
        <Typography
          variant="h6"
          sx={{ color: '#E96323', fontWeight: 'bold', whiteSpace: 'nowrap' }}
        >
          Formát reportu
        </Typography>
      </Box>

      {/* Radio Buttons */}
      <FormControl component="fieldset" disabled={loading}>
        <RadioGroup
          row
          aria-label="report-format"
          name="report-format"
          value={reportFormat}
          onChange={(e) => setReportFormat(e.target.value)}
          sx={{ marginLeft: '5px' }}
        >
          <FormControlLabel
            value="csv"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>CSV</Typography>}
          />
          <FormControlLabel
            value="XLSX"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>EXCEL</Typography>}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ReportFormatSelector;
