import {DrawSEManagerOverview, DrawSEManagerTemp, DrawSESesukOverview, DrawSESesukTemp} from "./solaredge"

function SE100KInverterSmall(props)
{
  const powerLevel_INV = props.INV.I_AC_Power || 0;
  const maxPower_INV = 100;
  const powerLevelWidth_INV = `${(powerLevel_INV / maxPower_INV) * 100}%`;

  return (
    <div className="inverter-wrapper-small" onClick={props.onClick}>
      {/* Blok střídače */}
      <div className={`inverter-block-small ${props.INV.I_Status === 7 ? 'inverter-block-small-error' : ''}`}>
        <div className="inverter-content-small">
          {/* Obrázek střídače a šipky */}
          <div className="inverter-image-small">
            <div className="inverter-top-bar-small">
              <h2>STŘÍDAČ Č.&nbsp;{props.number} - SOLAREDGE SE100K</h2>
            </div>
            <div className='inverter-status-small'>
              {props.INV.I_Status !== 7 && <img src="/images/ikona_ok_50x50.png" alt="OK" />}
              {props.INV.I_Status === 7 && <img className='inverter-status-small-error' src="/images/ikona_pruser_50x50.png" alt="OK" />}
            </div>

            <img src="/images/se100k.png" alt="SE100k" />
            {powerLevel_INV > 0 && (
              <>
                {/* Kontejner pro levé šipky mezi DC boxem a střídačem */}
                <div className="arrow-container-left">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>

                {/* Kontejner pro pravé šipky mezi střídačem a AC boxem */}
                <div className="arrow-container-right">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>
              </>
            )}
            {/* Kontejner pro ukazatel výkonu */}
            <div className="power-container-small">
              <div className="power-info-small">AKTUÁLNÍ VÝKON:</div>
              <div className="power-bar-small">
                <div className="power-level-small" style={{ width: powerLevelWidth_INV }}></div>
                <div className="power-value-small">{powerLevel_INV.toFixed(1)} kW</div>
              </div>
              <div className="power-scale-small">
                <span>0 kW</span>
                <span>{maxPower_INV / 2} kW</span>
                <span>{maxPower_INV} kW</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function DrawSE100KOverview(props)
{
  return (
    <svg width="900" height="600">
      <path d="M 325 250 C 325 375 325 375 413 375 C 435 375 435 375 435 500" stroke="white" fill="transparent" class="shadow"/>
      <path d="M 450 250 L 450 500" stroke="white" fill="transparent" class="shadow"/>
      <path d="M 575 250 C 575 375 575 375 497 375 C 465 375 465 375 465 500" stroke="white" fill="transparent" class="shadow"/>

      { props.INV.synergyUnits !== undefined && props.INV.synergyUnits[0].DCW > 0 &&
        <circle fill="#7dba00" cx="325" cy="250" r="5" class="shadow">
          <animateMotion path="M 0 0 C 0 125 0 125 88 125 C 110 125 110 125 110 250" begin="0s" dur="2s" repeatCount="indefinite" />
        </circle>
      }

      { props.INV.synergyUnits !== undefined && props.INV.synergyUnits[1].DCW > 0 &&
        <circle fill="#7dba00" cx="450" cy="250" r="5" class="shadow">
          <animateMotion path="M 0 0 L 0 250" begin="0s" dur="2s" repeatCount="indefinite" />
        </circle>
      }

      { props.INV.synergyUnits !== undefined && props.INV.synergyUnits[2].DCW > 0 &&
        <circle fill="#7dba00" cx="575" cy="250" r="5" class="shadow">
          <animateMotion path="M 0 0 C 0 125 0 125 -88 125 C -110 125 -110 125 -110 250" begin="0s" dur="2s" repeatCount="indefinite" />
        </circle>
      }

      <DrawSEManagerOverview x={450} y={500} x_box={185} y_box={500} INV={props.INV} />

      <DrawSESesukOverview x={325} y={250} x_box={225} y_box={100} INV={props.INV.synergyUnits[0]} />
      <DrawSESesukOverview x={450} y={250} x_box={450} y_box={100} INV={props.INV.synergyUnits[1]} />
      <DrawSESesukOverview x={575} y={250} x_box={675} y_box={100} INV={props.INV.synergyUnits[2]} />     
    </svg>
  );
}

function DrawSE100KTemp(props)
{

    return (
      <svg width="900" height="600">
        <path d="M 325 250 C 325 375 325 375 413 375 C 435 375 435 375 435 500" stroke="white" fill="transparent" class="shadow"/>
        <path d="M 450 250 L 450 500" stroke="white" fill="transparent" class="shadow"/>
        <path d="M 575 250 C 575 375 575 375 497 375 C 465 375 465 375 465 500" stroke="white" fill="transparent" class="shadow"/>

        <DrawSEManagerTemp x={450} y={500} x_box={185} y_box={500} INV={props.INV} />

        <DrawSESesukTemp x={325} y={250} x_box={225} y_box={100} INV={props.INV.synergyUnits[0]} />
        <DrawSESesukTemp x={450} y={250} x_box={450} y_box={100} INV={props.INV.synergyUnits[1]} />
        <DrawSESesukTemp x={575} y={250} x_box={675} y_box={100} INV={props.INV.synergyUnits[2]} />     
      </svg>
    );
}

export {SE100KInverterSmall, DrawSE100KOverview, DrawSE100KTemp};