// AdvancedReportTypeSelector.jsx

import React from 'react';
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';

const AdvancedReportTypeSelector = ({ selectedType, setSelectedType, loading }) => {
  // Define styles for the radio buttons
  const radioStyles = {
    '& .MuiSvgIcon-root': {
      borderRadius: '50%', // Kulaté okraje
      backgroundColor: '#ffffff', // Výplň bílé barvy
      color: '#e96323', // Oranžová barva prstence
      fontSize: '20px', // Velikost ikony (zmenšení)
      transition: 'transform 0.2s ease', // Animace pro plynulý přechod
    },
    '&.Mui-checked': {
      color: '#e96323', // Barva prstence po výběru
      '& .MuiSvgIcon-root': {
        fontSize: '22px', // Zvýšení velikosti oranžového bodu při výběru
      },
    },
  };

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ display: 'flex', alignItems: 'center', color: '#E96323', fontWeight: 'bold' }}
      >
        <BarChartIcon sx={{ marginRight: '8px' }} />
        Typ pokročilého reportu
      </Typography>
      <FormControl component="fieldset" disabled={loading}>
        <RadioGroup
          row
          aria-label="advanced-report-type"
          name="advanced-report-type"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          sx={{ marginLeft: '5px' }}
        >
          <FormControlLabel
            value="monthly"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>Měsíční</Typography>}
          />
          <FormControlLabel
            value="yearly"
            control={<Radio sx={radioStyles} />}
            label={<Typography sx={{ color: '#ffffff' }}>Roční</Typography>}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default AdvancedReportTypeSelector;
